import { IQuery, Topics } from '@zaplify/common';
import { OwnersDto } from '../prospects';

export class GetOwnersQuery extends IQuery<OwnersDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly params: {
            prospectIds: string[];
            personIds: string[];
            linkedinProfileUrls: string[];
            linkedinMemberIds: string[];
            userOrganizationId: string;
        },
    ) {
        super();
    }
}

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';
import { CheckIcon } from 'lucide-react';
import { useUser } from '../../../../hooks/use-user';
import { useFeedOnboarding } from '../hooks/use-feed-onboarding';
import { TypewriterText } from '../../../../components/typewriter-text';
import { useOnboardingStage } from '../hooks/use-onboarding-stage';

export const OnboardingHeader: React.FC = () => {
    const { firstName } = useUser();
    const { steps, onboardingStepsComplete } = useFeedOnboarding();
    const { isIntro, isPlaybook } = useOnboardingStage();
    const [showCompletionMessage, setShowCompletionMessage] = useState(false);

    useEffect(() => {
        if (onboardingStepsComplete && !isIntro) {
            // Add a slight delay before showing the completion message
            const timer = setTimeout(() => {
                setShowCompletionMessage(true);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [onboardingStepsComplete, isIntro]);

    return (
        <div className="flex flex-col items-start w-full max-w-[800px] mx-auto gap-4">
            <AnimatePresence mode="sync">
                {!showCompletionMessage && (
                    <motion.div
                        className="w-full"
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <p className="text-gray-600 text-lg">
                            <TypewriterText
                                text={`Hi ${firstName}, welcome to Andsend - your AI-powered customer relationship agent! We're setting up your experience by analyzing your profile, website and past conversations.`}
                                delay={0.5}
                            />
                        </p>

                        <div className="grid grid-cols-3 gap-4 w-full mt-4">
                            {Object.values(steps).map((step, index) => {
                                const isComplete = step.status === 'completed';
                                const isActive = step.status === 'in-progress';
                                const isPending = step.status === 'pending';

                                return (
                                    <div
                                        key={step.id}
                                        className={cn(
                                            'flex flex-col items-start space-y-2 p-4 rounded-lg border border-border',
                                            isComplete && 'border-success-500'
                                        )}
                                    >
                                        <span className="text-sm font-medium text-gray-900 flex items-center justify-between w-full">
                                            <div className="flex items-center gap-2">{step.title}</div>
                                            <div className="flex items-center gap-2">
                                                {isComplete && <CheckIcon className="w-4 h-4 text-success-500" />}
                                            </div>
                                        </span>
                                        <div className="w-full bg-gray-200 h-1.5 rounded-full">
                                            <motion.div
                                                className={cn(
                                                    'h-full rounded-full',
                                                    isComplete ? 'bg-blue-500' : 'bg-blue-500'
                                                )}
                                                initial={{ width: '0%' }}
                                                animate={{
                                                    width: `${step.progress}%`,
                                                }}
                                                transition={{ duration: 1, delay: index * 0.4 }}
                                            />
                                        </div>
                                        {isActive && <span className="text-xs text-gray-500">{step.description}</span>}
                                        {isComplete && <span className="text-xs text-gray-500">Completed</span>}
                                        {isPending && <span className="text-xs text-gray-500">Waiting to start</span>}
                                    </div>
                                );
                            })}
                        </div>
                    </motion.div>
                )}

                {showCompletionMessage && isPlaybook && (
                    <motion.div
                        className="w-full flex justify-center py-2"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, delay: 0.3 }}
                    >
                        <div className="grid grid-cols-3 gap-4 w-full mt-4">
                            {Object.values(steps).map((step, index) => (
                                <div
                                    key={step.id}
                                    className="flex flex-col items-start space-y-2 p-4 rounded-lg border border-success-500"
                                >
                                    <span className="text-sm font-medium text-gray-900 flex items-center justify-between w-full">
                                        <div className="flex items-center gap-2">{step.title}</div>
                                        <div className="flex items-center gap-2">
                                            <CheckIcon className="w-4 h-4 text-success-500" />
                                        </div>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

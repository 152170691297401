export const paths = {
    OLD_LOGIN: '/old-login',
    OLD_REGISTER: '/old-register',
    OLD_CONFIRM_EMAIL: '/old-confirm-email',
    OLD_RESET_PASSWORD: '/old-reset-password',
    OLD_RESET_PASSWORD_COMPLETED: '/old-reset-password-completed',
    OLD_CONFIRM_PASSWORD_RESET: '/old-confirm-password-reset',
    OLD_CONFIGURE_PROFILE: '/register/configure',
    OLD_ACCOUNTS: '/accounts',
    OLD_JOIN_ORGANIZATION: '/old-join-organization',
    OLD_SCHEDULE_ONBOARDING: '/old-schedule-onboarding',
    // Remove the above paths
    HOME: '/',
    AUTH: '/auth',
    NOT_FOUND: '/not-found',
    NEW_LOGIN: '/login',
    NEW_REGISTER: '/register',
    NEW_REFERRAL: '/join',
    NEW_CONFIRM_EMAIL: '/confirm-email',
    NEW_RESET_PASSWORD: '/reset-password',
    NEW_CONFIRM_PASSWORD_RESET: '/confirm-password-reset',
    NEW_JOIN_ORGANIZATION: '/join-organization',
    WORKSPACES: {
        ROOT: '/workspaces',
        NEW: '/workspaces/new',
        WORKSPACE_ID: '/workspaces/:workspaceId?',
    },
    SERVER_OFFLINE: '/offline',
    LEGAL: {
        TERMS: '/terms-of-service',
        PRIVACY_POLICY: '/privacy-policy',
        DPA: 'https://drive.google.com/file/d/16Zg--2rq8IcYYkbpudt4miecLJXJX9BR/view',
        PRIVACY_POLICY_2024_12_19: '/privacy-policy-2024-12-19',
        TERMS_2023_07_09: '/terms-of-service-2023-07-09',
        TERMS_2024_12_19: '/terms-of-service-2024-12-19',
    },
    CAMPAIGNS: '/campaigns',
    MANAGE_PROSPECTS: '/manage-prospects',
    EDIT_CAMPAIGN: '/campaigns/edit',
    CHANNEL_ACCOUNTS: '/channel-accounts',
    INTEGRATIONS: '/integrations',
    ACCOUNT_MANAGEMENT: '/account-management',
    REFERRAL_LINKS: '/referral-links',
    INVITE_USERS: '/invite-users',
    OUTREACH_ACCOUNTS: '/outreach-accounts',
    OUTREACH_SETTINGS: '/outreach-settings',
    PROSPECTING: '/prospecting',
    PROSPECTING_PATHS: {
        SUGGESTIONS: '/prospecting/suggestions',
        SEARCH: '/prospecting/search',
        IMPORT_LINKEDIN: '/prospecting/import-linkedin',
        IMPORT_PROSPECTS: '/prospecting/import-prospects',
    },
    DISCOVER: '/discover',
    DISCOVER_PATHS: {
        SUGGESTIONS: '/discover/suggestions',
        SEARCH: '/discover/search',
    },
    IMPORT: '/import',
    IMPORT_PATHS: {
        LINKEDIN: '/import/import-linkedin',
        PROSPECTS: '/import/import-prospects',
    },
    CONTACTS: '/contacts',
    CHATS: '/chats',
    TODOS: '/todos',
    PLAYBOOKS: '/playbooks',
    PLAYBOOKS_PATHS: {
        NEW: '/playbooks/new',
    },
    GET_STARTED: '/get-started',
    INITIAL_CREATE_CAMPAIGN_VIEW: '/create-campaign',
    ONBOARDING_CONNECT_LINKEDIN: '/connect-linkedin',
    ONBOARDING_AI_PREFERENCES: '/ai-preferences',
    SUGGESTIONS: '/prospecting/suggestions',
    SEARCH: '/prospecting/search',
    IMPORT_CSV_PROSPECTS: '/prospecting/import-prospects',
    NEW_ZAPLIFY_FLOW_WELCOME_VIEW: '/new-zaplify-welcome',
    NEW_ZAPLIFY_FLOW_CONNECT_LINKEDIN: '/new-zaplify-connect-linkedin',
    NEW_ZAPLIFY_FLOW_AI_PREFERENCES: '/new-zaplify-ai-preferences',
    CAMPAIGNS_MIGRATE: '/campaigns-migrate',
    MODALS: {
        GLOBAL_SEARCH: '/global-search',
    },
    NEW: {
        IMPORT: '/new/discover/import',
        IMPORT_PATHS: {
            LINKEDIN: '/new/discover/import/linkedin',
            LIST: '/new/discover/import/list',
        },
        ACTIONS: '/new/actions',
        ACTION_FEED: '/new/action-feed',
        MESSAGES: '/new/messages',
        CONTACTS: '/new/contacts',
        PLAYBOOKS: '/new/playbooks',
        DISCOVER: '/new/discover',
        DISCOVER_PATHS: {
            SUGGESTIONS: '/new/discover/suggestions',
            SEARCH: '/new/discover/search',
        },
        ACCOUNT_MANAGEMENT_PATHS: {
            ROOT: '/new/account-management',
            PROFILE: 'profile',
            MEMORIES: 'memories',
            USERS: 'users',
            APPS: 'apps',
            SUBSCRIPTION: 'subscription',
            OUTREACH_SETTINGS: 'outreach-settings',
        },
        ONBOARDING_PATHS: {
            ROOT: '/onboarding',
            CONNECT_LINKEDIN: '/onboarding/connect-linkedin',
            SETUP_PLAYBOOK: '/onboarding/setup-playbook',
            ACTION_FEED: '/new/action-feed/onboarding',
            ACTION_FEED_FINAL: '/new/action-feed/onboarding/final',
        },
    },
};

export const unprotectedPaths = [
    paths.OLD_LOGIN,
    paths.OLD_REGISTER,
    paths.OLD_RESET_PASSWORD,
    paths.OLD_RESET_PASSWORD_COMPLETED,
    paths.OLD_JOIN_ORGANIZATION,
    paths.NEW_JOIN_ORGANIZATION,
    paths.AUTH,
    paths.NEW_LOGIN,
    paths.NEW_REGISTER,
    paths.NEW_CONFIRM_EMAIL,
    paths.LEGAL.TERMS,
    paths.LEGAL.TERMS_2023_07_09,
    paths.LEGAL.TERMS_2024_12_19,
    paths.LEGAL.PRIVACY_POLICY,
    paths.LEGAL.PRIVACY_POLICY_2024_12_19,
    paths.NOT_FOUND,
];

export const unprotectedPathsDoNotRedirectToHome = [
    paths.OLD_JOIN_ORGANIZATION,
    paths.NEW_JOIN_ORGANIZATION,
    paths.LEGAL.TERMS,
    paths.LEGAL.TERMS_2023_07_09,
    paths.LEGAL.TERMS_2024_12_19,
    paths.LEGAL.PRIVACY_POLICY,
    paths.LEGAL.PRIVACY_POLICY_2024_12_19,
    paths.NOT_FOUND,
];

export const authPaths = [
    paths.OLD_CONFIRM_EMAIL,
    paths.OLD_CONFIGURE_PROFILE,
    paths.OLD_ACCOUNTS,
    paths.NEW_CONFIRM_EMAIL,
    // paths.NEW_CONFIGURE_PROFILE,
    // paths.NEW_ACCOUNTS,
];

export const onboardingPaths = [paths.ONBOARDING_CONNECT_LINKEDIN, paths.ONBOARDING_AI_PREFERENCES];

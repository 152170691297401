import { Route, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import { paths } from '../paths';

import { RootLayout } from '../../new/layouts/root-layout';
import { AppLayout } from '../../new/layouts/app-layout';
import { AllConversationsPage } from '../../new/pages/messages/all-conversations-page';
import { ActionFeedPage } from '../../new/pages/action-feed/action-feed-page';
import { ActionsPage } from '../../new/pages/actions/actions-page';
import { ContactsPage } from '../../new/pages/contacts/contacts-page';
import { PlaybooksPage } from '../../new/pages/playbooks/playbooks-page';
import { DiscoverPage } from '../../new/pages/discover/discover-page';

import { DashboardPage } from '../../new/pages/dashboard/dashboard-page';
import { HomePage } from '../../new/pages/home/home-page';
import { AdminPage } from '../../new/pages/admin/admin-page';
import RequireAuth from '../require-auth';
import { Conversation } from '../../new/pages/actions/components/conversation';
import { NoConversation } from '../../new/pages/actions/components/no-conversation';
import { ConversationSkeleton } from '../../new/pages/actions/components/conversation';
import { SuggestionsPage } from '../../new/pages/discover/suggestions/suggestions-page';
import { PlaybookFormPage } from '../../new/pages/playbooks/pages/playbook-form-page';
import { ImportLinkedinPage } from '../../new/pages/import/linkedin/import-linkedin-page';
import { ImportPage } from '../../new/pages/import/import-page';
import { SearchPage } from '../../new/pages/discover/search/search-page';
import { ContactSuggestionsFeed } from '../../new/pages/discover/suggestions/components/contact-suggestions-feed';
import { ReactRouterErrorBoundary } from '../react-router-error-boundary';
import NoConversationSelected from '../../new/pages/messages/components/no-conversation-selected';
import { ImportListPage } from '../../new/pages/import/list/import-list-page';
import { accountManagementRoutes } from './account-management-routes';
import { onboardingRoutes } from './onboarding-routes';
import { OnboardingFeedPage } from '../../new/pages/action-feed/onboarding/onboarding-feed-page';
import { OnboardingFeedFinalPage } from '../../new/pages/action-feed/onboarding/final/onboarding-feed-final-page';

export const newAppRoutes = (
    <>
        <Route path="/new" element={<RootLayout />} ErrorBoundary={ReactRouterErrorBoundary}>
            <Route element={<RequireAuth requiredUserState={'loggedin_user_selected_and_fully_configured'} />}>
                <Route index element={<Navigate to={paths.NEW.ACTIONS} replace />} />
                <Route element={<AppLayout />} ErrorBoundary={ReactRouterErrorBoundary}>
                    <Route path={'messages'} element={<AllConversationsPage />}>
                        <Route index element={<NoConversationSelected />} />
                        <Route
                            path={':prospectId'}
                            element={
                                <Suspense fallback={<ConversationSkeleton />}>
                                    <Conversation />
                                </Suspense>
                            }
                            ErrorBoundary={ReactRouterErrorBoundary}
                        />
                    </Route>

                    <Route path="action-feed" ErrorBoundary={ReactRouterErrorBoundary}>
                        <Route index element={<ActionFeedPage />} />
                        <Route path=":prospectId" element={<ActionFeedPage />} />
                        <Route path="onboarding">
                            <Route index element={<OnboardingFeedPage />} />
                            <Route path="final">
                                <Route index element={<OnboardingFeedFinalPage />} />
                                <Route path=":prospectId" element={<OnboardingFeedFinalPage />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path="actions" element={<ActionsPage />} ErrorBoundary={ReactRouterErrorBoundary}>
                        <Route index element={<NoConversation />} />
                        <Route
                            path="messages/:prospectId"
                            element={
                                <Suspense fallback={<ConversationSkeleton />}>
                                    <Conversation />
                                </Suspense>
                            }
                            ErrorBoundary={ReactRouterErrorBoundary}
                        />
                    </Route>

                    <Route path="contacts" element={<ContactsPage />} ErrorBoundary={ReactRouterErrorBoundary}>
                        <Route path="add" element={<div>Add contact rendered as modal</div>} />
                        <Route path=":prospectId" element={<ContactsPage />} />
                    </Route>

                    <Route path={'playbooks'} element={<PlaybooksPage />} ErrorBoundary={ReactRouterErrorBoundary} />
                    <Route
                        path={'playbooks/new'}
                        element={<PlaybookFormPage />}
                        ErrorBoundary={ReactRouterErrorBoundary}
                    />
                    <Route
                        path={'playbooks/:groupId'}
                        element={<PlaybookFormPage />}
                        ErrorBoundary={ReactRouterErrorBoundary}
                    />

                    <Route path={'discover'} element={<DiscoverPage />} ErrorBoundary={ReactRouterErrorBoundary}>
                        <Route index element={<Navigate to="suggestions" replace />} />
                        <Route
                            path="suggestions"
                            element={<SuggestionsPage />}
                            ErrorBoundary={ReactRouterErrorBoundary}
                        >
                            <Route path=":contactSuggestionId?" element={<ContactSuggestionsFeed />} />
                        </Route>
                        <Route path={'import'} element={<ImportPage />} ErrorBoundary={ReactRouterErrorBoundary}>
                            <Route path={'linkedin'} element={<ImportLinkedinPage />} />
                            <Route path={'list'} element={<ImportListPage />} />
                        </Route>
                        <Route path={'search'} element={<SearchPage />}>
                            <Route path=":memberId" element={<SearchPage />} />
                        </Route>
                    </Route>

                    {accountManagementRoutes}

                    <Route path="statistics/:tab?" element={<DashboardPage />} />
                    <Route path={'home'} element={<HomePage />} />
                    <Route
                        element={
                            <RequireAuth
                                requiredUserState={'loggedin_user_selected_and_fully_configured_and_is_admin'}
                                globalAdmin={['isGlobalAdmin']}
                            />
                        }
                    >
                        <Route path={'admin'} element={<AdminPage />} />
                    </Route>
                </Route>
            </Route>
        </Route>
        {onboardingRoutes}
    </>
);

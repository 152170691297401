import React, { Suspense, useEffect, useMemo } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Loader2, PanelRight, AlarmClock, Filter, ArrowLeft, UserRound } from 'lucide-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';

import { SidebarTrigger } from '@shadcn/ui/components/ui/sidebar';
import { KeyboardSymbol, OSMetaKey } from '@shadcn/ui/components/keyboard-symbol';
import { ActionsCountBadge } from './actions-count-badge';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { useQuery } from '@tanstack/react-query';
import { ConnectionStatusBadge } from '../pages/actions/components/connection-status-badge';
import { PlaybookBadge } from './playbook-badge';
import { useSnoozeDialog } from './dialogs/snooze-dialog';
import { useSdk } from '../sdk';
import { useLinkedin } from '../hooks/use-linkedin';
import { PlaybookFilterBadge } from './playbook-filter-badge';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { cn } from '@shadcn/ui/lib/utils';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import {
    DropdownMenu,
    DropdownMenuItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { DotsHorizontalIcon, DotsVerticalIcon } from '@radix-ui/react-icons';

interface ActionsPageHeaderProps {
    onToggleDetails: () => void;
    isDetailsOpen: boolean;
}

export const ConversationPageHeaderSkeleton: React.FC = () => {
    const location = useLocation();
    const isOnActionsPage = location.pathname.includes('actions');
    return (
        <header className="sticky top-0 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <div className="flex h-14 items-center justify-between gap-4 px-4">
                {/* Left section */}
                <div className="flex items-center gap-4 w-[304px] border-r h-full pr-4">
                    <span className="font-medium text-2xl">{isOnActionsPage ? 'Actions' : 'Conversations'}</span>
                    <Skeleton className="w-8 h-8 rounded-full bg-background-brand-primary-subtle" />
                </div>
                {/* Center section */}
                <div className="flex flex-1 items-center justify-between pl-4">
                    <div className="flex items-center gap-3 flex-grow">
                        <Skeleton className="w-48 h-2 bg-background-brand-primary-subtle" />
                    </div>

                    {/* Right section */}
                    <div className="flex items-center gap-4">
                        {isOnActionsPage && (
                            <Skeleton className="w-16 h-8 rounded-md bg-background-brand-primary-subtle" />
                        )}
                        <Skeleton className="w-16 h-8 rounded-md bg-background-brand-primary-subtle" />
                    </div>
                </div>
            </div>
        </header>
    );
};

export const ConversationPageHeader: React.FC<ActionsPageHeaderProps> = ({ onToggleDetails, isDetailsOpen }) => {
    const { prospectId } = useParams();
    const location = useLocation();
    const isOnActionsPage = location.pathname.includes('actions');
    const { openSnoozeDialog } = useSnoozeDialog();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const {
        prospect: { getProspectById },
    } = useSdk();

    const { data } = useQuery(getProspectById(prospectId));

    const contact = useMemo(() => {
        if (data?.data) return data?.data;
    }, [data]);

    // Handle keyboard shortcuts
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const activeElement = document.activeElement;
            const magicKey = event.ctrlKey || event.metaKey;
            const isTyping =
                activeElement?.tagName === 'INPUT' ||
                activeElement?.tagName === 'TEXTAREA' ||
                activeElement?.getAttribute('contenteditable') === 'true';

            if (magicKey && event.key === 'i' && !isTyping) {
                onToggleDetails();
            } else if (isOnActionsPage && prospectId && event.key === 's' && !isTyping) {
                openSnoozeDialog({ prospectId });
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [onToggleDetails, prospectId]);

    return (
        <header className="sticky top-0 w-full min-w-full h-14 border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <div className="flex h-14 items-center justify-between gap-4 px-4">
                {/* Left section */}
                <div
                    className={cn(
                        'flex items-center gap-4 w-full min-w-full md:min-w-[304px] md:w-[304px] md:border-r justify-between h-full md:pr-4',
                        { 'hidden md:flex': prospectId }
                    )}
                >
                    <div
                        className="chats-title flex items-center gap-4" // @IMPORTANT Needed for frigade tour (classname must be exact)
                    >
                        <span className={`font-medium text-2xl`}>
                            {isOnActionsPage ? 'Actions' : 'All conversations'}
                        </span>
                        {isOnActionsPage && (
                            <Suspense
                                fallback={
                                    <Badge
                                        variant="secondary"
                                        className="w-fit bg-background-warning-secondary border-border-primary"
                                    >
                                        <Loader2 className="h-4 w-4 animate-spin" />
                                    </Badge>
                                }
                            >
                                <div className="flex items-center">
                                    <ActionsCountBadge />
                                </div>
                            </Suspense>
                        )}
                    </div>
                    {isOnActionsPage && <PlaybookFilterBadge size="sm" />}
                    {/* <Button
                        variant="secondary"
                        size="icon"
                        className="ml-auto p-0 w-9 h-9 rounded-lg shadow-md shadow-background-primary-on-brand min-w-0 bg-background border border-border flex items-center bg-gradient-to-r from-background-brand-primary/20 from-[6.25%] to-background-brand-primary/10 to-[84.37%] hover:bg-gradient-to-r hover:from-background-brand-primary/20 hover:from-[2.25%] hover:to-background-brand-primary/10 hover:to-[40.37%] dark:from-background-brand-primary-subtle/30 dark:to-background-brand-primary/20 dark:hover:from-background-brand-primary-subtle/40 dark:hover:to-background-brand-primary/30"
                    >
                        <AndSendIcon variation="color-striped" />
                    </Button> */}
                </div>

                {/* Right section */}
                {contact && (
                    <div className="flex flex-1 items-center justify-between w-full">
                        <Button
                            variant="outline"
                            size="sm"
                            className="sm:flex md:hidden flex items-center gap-2"
                            onClick={() => {
                                const { pathname, search } = window.location;
                                const newPath = pathname.split('/').slice(0, -1).join('/');
                                navigate(`${newPath}${search}`);
                            }}
                        >
                            <ArrowLeft className="h-4 w-4" />
                            <span className="hidden xl:inline">Back</span>
                        </Button>
                        <div className="flex items-center gap-3">
                            {!isDetailsOpen && !isMobile ? (
                                <div className="flex flex-col gap-0">
                                    <div className="flex gap-2 items-center">
                                        <span className="font-medium text-lg line-clamp-1 text-nowrap">
                                            {contact.fullName}
                                        </span>
                                        <ConnectionStatusBadge key={prospectId} prospectId={prospectId} />
                                        <PlaybookBadge prospectId={prospectId} />
                                    </div>
                                    <span className="text-xs text-text-tertiary">
                                        {contact.occupationTitle} - {contact.organizationName}
                                    </span>
                                </div>
                            ) : (
                                <div className="flex flex-1 items-center gap-3">
                                    <span className="font-medium text-base md:text-lg line-clamp-1 text-nowrap">
                                        {contact.fullName}
                                    </span>
                                    <ConnectionStatusBadge key={prospectId} prospectId={prospectId} />
                                </div>
                            )}
                        </div>
                        <div className="flex items-center gap-2 justify-end">
                            {isOnActionsPage && !isMobile && (
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <div>
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    className="flex items-center gap-2"
                                                    onClick={() => openSnoozeDialog({ prospectId })}
                                                >
                                                    <AlarmClock className="h-4 w-4" />
                                                    <span className="hidden lg:inline">Snooze</span>
                                                </Button>
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            Snooze action{' '}
                                            <KeyboardSymbol>
                                                <span>s</span>
                                            </KeyboardSymbol>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            )}

                            {!isDetailsOpen && !isMobile && (
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="outline"
                                                size="sm"
                                                className="flex items-center gap-2 animate-in fade-in-0 zoom-in-95"
                                                onClick={onToggleDetails}
                                            >
                                                <PanelRight className="h-4 w-4" />
                                                <span className="hidden xl:inline">View Details</span>
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            Open contact details{' '}
                                            <KeyboardSymbol>
                                                <OSMetaKey />
                                                +i
                                            </KeyboardSymbol>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            )}
                            {isMobile && isOnActionsPage && (
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="outline" size="sm" className="flex items-center gap-2">
                                            <DotsHorizontalIcon className="h-4 w-4" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem onClick={onToggleDetails}>
                                            <UserRound className="h-4 w-4" />
                                            <span>View Details</span>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => openSnoozeDialog({ prospectId })}>
                                            <AlarmClock className="h-4 w-4" />
                                            <span>Snooze Action</span>
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </header>
    );
};

export default ConversationPageHeader;

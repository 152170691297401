import { CardContent } from '@shadcn/ui/components/ui/card';
import { FC, useEffect, useState } from 'react';
import { motion } from 'motion/react';
import { Card } from '@shadcn/ui/components/ui/card';
import { Button } from '@shadcn/ui/components/ui/button';
import { TypewriterText } from '../../../../components/typewriter-text';

export const OnboardingIntroCard: FC<{ onContinue: () => void }> = ({ onContinue }) => {
    const [showGetStarted, setShowGetStarted] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowGetStarted(true);
        }, 8000);
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="w-full"
        >
            <Card className="border border-gray-200 shadow-sm w-full">
                <CardContent className="p-6">
                    <div className="space-y-4">
                        <div className="flex items-center gap-2">
                            <h3 className="text-lg font-medium">Unlock relationship-driven success in three steps:</h3>
                        </div>

                        <div className="space-y-3 pl-1">
                            <NumberedListItem
                                number={1}
                                text={
                                    <TypewriterText
                                        delay={0}
                                        text="Create and update playbooks to guide authentic conversations"
                                    />
                                }
                            />
                            <NumberedListItem
                                number={2}
                                text={
                                    <TypewriterText
                                        delay={1.5}
                                        text="Add 10+ contacts to each playbook to receive relevant recommendations"
                                    />
                                }
                            />
                            <NumberedListItem
                                number={3}
                                text={
                                    <TypewriterText
                                        delay={3}
                                        text="Consistent engagement - send 10+ messages in your first week"
                                    />
                                }
                            />
                        </div>

                        <p className="text-sm text-gray-600 mt-3">
                            <TypewriterText
                                delay={4.5}
                                text="Your agent learns from every interaction, continuously improving to help you build meaningful relationships that drive sales results."
                            />
                        </p>

                        <div className="flex justify-end pt-2">
                            {showGetStarted && (
                                <Button onClick={onContinue} className="ml-auto animate-fade-in">
                                    Let's get started
                                </Button>
                            )}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </motion.div>
    );
};

const NumberedListItem: FC<{ number: number; text: React.ReactNode }> = ({ number, text }) => {
    return (
        <div className="flex items-start gap-3">
            <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-100 flex items-center justify-center text-sm font-medium text-blue-600">
                {number}
            </div>
            <p className="text-gray-700 pt-0.5">{text}</p>
        </div>
    );
};

import { AnimatePresence, motion } from 'motion/react';
import { OnboardingActionCard } from '../components/first-action-card';
import { FC } from 'react';
import ContactSuggestionsCard from '../components/contact-suggestions-card';
import { CheckCircle, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { paths } from '../../../../../routes/paths';
import { Button } from '@shadcn/ui/components/ui/button';

export const OnboardingFeedFinalPage: FC = () => {
    return (
        <div className="h-full w-full overflow-y-auto">
            <div className="flex flex-col items-center w-full max-w-3xl mx-auto p-4 gap-6 pt-8">
                <div className="flex flex-col items-start w-full">
                    <h1 className="text-3xl font-semibold text-gray-900 text-left ">
                        Supercharging your customer relationships
                    </h1>
                </div>

                {
                    <motion.div
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        className="w-full"
                    >
                        <OnboardingActionCard />
                    </motion.div>
                }
                {
                    <motion.div
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1, delay: 0.3 }}
                    >
                        <ContactSuggestionsCard />
                    </motion.div>
                }
                {<OnboardingCompletedSection />}
            </div>
        </div>
    );
};

const OnboardingCompletedSection: FC = () => {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 1.5 }}
                className={`flex flex-col items-center gap-4 py-8`}
            >
                <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center">
                    <CheckCircle size={30} className="text-green-500 animate-slide-in-from-top" />
                </div>
                <h1 className="text-3xl font-semibold text-gray-900">Your Andsend experience is ready!</h1>
                <p className="text-gray-600 text-lg text-center">
                    Ready to start using Andsend? Press the button to open the full experience or keep interacting with
                    the cards to learn more.
                </p>
                <Link to={paths.NEW.ACTIONS} reloadDocument>
                    <Button variant="default" size="lg" className="py-4">
                        Go to Action Feed
                        <ChevronRight size={16} />
                    </Button>
                </Link>
            </motion.div>
        </AnimatePresence>
    );
};

import { Avatar, AvatarFallback } from '@shadcn/ui/components/ui/avatar';
import { getInitials } from '../../../functions/get-initials';

import { cn } from '@shadcn/ui/lib/utils';
import { Users } from 'lucide-react';

import { cva, type VariantProps } from 'class-variance-authority';
import { Tooltip, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { TooltipContent } from '@shadcn/ui/components/ui/tooltip';
import { TooltipProvider } from '@shadcn/ui/components/ui/tooltip';
import { useContactOwners } from '../../../hooks/use-contact-owners';

const containerVariants = cva('flex flex-row items-center', {
    variants: {
        spacing: {
            tight: 'gap-2',
            normal: 'gap-3',
            loose: 'gap-4',
        },
    },
    defaultVariants: {
        spacing: 'normal',
    },
});

const iconVariants = cva('', {
    variants: {
        iconSize: {
            hidden: 'hidden',
            sm: 'h-5 w-5 stroke-[1]',
            md: 'h-6 w-6 stroke-[1]',
            lg: 'h-7 w-7 stroke-[1]',
        },
    },
    defaultVariants: {
        iconSize: 'sm',
    },
});

const avatarVariants = cva('', {
    variants: {
        avatarSize: {
            sm: 'h-6 w-6 text-xs',
            md: 'w-7 h-7',
            lg: 'w-10 h-10',
        },
    },
    defaultVariants: {
        avatarSize: 'sm',
    },
});

interface ContactOwnersAvatarDisplayProps
    extends VariantProps<typeof containerVariants>,
        VariantProps<typeof iconVariants>,
        VariantProps<typeof avatarVariants> {
    personId?: string;
    linkedinUrl?: string;
    linkedinMemberId?: string;
    prospectId?: string;
    className?: string;
}

export const ContactOwnersAvatarDisplay = ({
    personId,
    linkedinUrl,
    linkedinMemberId,
    prospectId,
    spacing,
    iconSize,
    avatarSize,
    className,
}: ContactOwnersAvatarDisplayProps) => {
    const { otherOwnersInOrganization } = useContactOwners({
        personId,
        linkedinUrl,
        prospectId,
        linkedinMemberId,
    });

    if (otherOwnersInOrganization.length === 0) {
        return null;
    }

    return (
        <div className={cn(containerVariants({ spacing }), className)}>
            <Users className={cn(iconVariants({ iconSize }))} />
            <div className="flex items-center flex-row gap-1">
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center [&>*:not(:first-child)]:-ml-2">
                                {otherOwnersInOrganization.slice(0, 3).map((owner) => (
                                    <Avatar
                                        className={cn(
                                            avatarVariants({ avatarSize }),
                                            'border border-background-primary'
                                        )}
                                        key={owner.userId}
                                    >
                                        <AvatarFallback className={cn(avatarVariants({ avatarSize }))}>
                                            {getInitials(owner.fullName)}
                                        </AvatarFallback>
                                    </Avatar>
                                ))}
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p className="text-xs">
                                Added by: {otherOwnersInOrganization.map((owner) => owner.fullName).join(', ')}
                            </p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </div>
        </div>
    );
};

import { useRef } from 'react';
import { AssistantCloud, AssistantRuntimeProvider, WebSpeechSynthesisAdapter } from '@assistant-ui/react';
import { useChatRuntime } from '@assistant-ui/react-ai-sdk';
import { useAuth } from '../../../providers/authentication-provider';
import { apiEndpoint } from '@zaplify/sdk';

export function AndsendAssistantRuntimeProvider({ children }: { children: React.ReactNode }) {
    const threadIdRef = useRef<string | undefined>(undefined);
    const {
        authState: { tokenResult },
    } = useAuth();

    const cloud = new AssistantCloud({
        baseUrl: import.meta.env.VITE_ASSISTANT_UI_BASE_URL,
        authToken: () => Promise.resolve(`${tokenResult?.token}`),
    });

    // const cloud = new AssistantCloud({
    //     baseUrl: `${apiEndpoint.replace('/v1', '')}`,
    //     authToken: () => Promise.resolve(`${tokenResult?.token}`),
    // });

    const runtime = useChatRuntime({
        api: `${apiEndpoint}/agent/chat`,
        headers: {
            Authorization: `Bearer ${tokenResult?.token}`,
        },
        adapters: {
            speech: new WebSpeechSynthesisAdapter(),
        },
        onFinish(message) {
            console.log('assistantmessage', message);
        },
        cloud,
    });

    return <AssistantRuntimeProvider runtime={runtime}>{children}</AssistantRuntimeProvider>;
}

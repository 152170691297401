import { AndsendAssistantRuntimeProvider } from './providers/andsend-assistant-runtime-provider';
// import { ChartToolUI } from '../components/tools/chart-tools-ui';
// import { Thread } from '@shadcn/ui/components/assistant-ui/thread';
import { PriceSnapshotTool } from './components/tools/price-snapshot';
import { Thread, ThreadList, AssistantMessage } from '@assistant-ui/react-ui';
import { WeatherTool } from './components/tools/weather-tool';
import { ActionsListTool } from './components/tools/actions-list';
import { ThreadWelcome } from './components/thread-welcome';
import { playbookToolsUI } from './components/tools/playbook-tools-ui';
import { MarkdownText } from '@shadcn/ui/components/assistant-ui/markdown-text';
import { ToolFallback } from '@shadcn/ui/components/assistant-ui/tool-fallback';
import { UserMessage } from './components/user-message';
import { contactToolsUI } from './components/tools/contact-tools-ui';
import { webSearchToolsUI } from './components/tools/web-search-tools-ui';
import { PageHeader } from '../../components/page-header';

export const HomePage = () => {
    return (
        <div className="flex flex-col w-full h-full p-4">
            {/* <PageHeader title="Agent" description="Your AI assistant" /> */}
            <div className="flex flex-row w-full h-full [&_.aui-avatar-image]:object-contain [&_.aui-avatar-image]:scale-[0.70] [&_.aui-avatar-root]:rounded-full [&_.aui-avatar-root]:bg-background-tertiary [&_.aui-thread-viewport-footer]:z-40">
                <AndsendAssistantRuntimeProvider>
                    <div className="flex flex-col w-[20%] h-full self-start border-r pr-4">
                        <ThreadList />
                    </div>

                    <div className="flex h-full flex-col w-[80%]">
                        <Thread
                            welcome={{
                                message: "Hi, I'm your Customer Relationship Agent. How can I help you today?",
                                suggestions: [
                                    {
                                        prompt: 'What should I do today?',
                                    },
                                    {
                                        prompt: 'What playbooks do I have?',
                                    },
                                    {
                                        prompt: 'Show me the best contacts in my network',
                                    },
                                ],
                            }}
                            composer={{
                                allowAttachments: true,
                            }}
                            components={{
                                ThreadWelcome: ThreadWelcome,
                                UserMessage: UserMessage,
                                // AssistantMessage: AssistantMessage,
                            }}
                            tools={[
                                PriceSnapshotTool,
                                WeatherTool,
                                ActionsListTool,
                                ...playbookToolsUI,
                                ...contactToolsUI,
                                ...webSearchToolsUI,
                            ]}
                            assistantMessage={{
                                components: {
                                    Text: MarkdownText,
                                    ToolFallback: ToolFallback,
                                },
                            }}
                            assistantAvatar={{
                                src: '/images/logos/andsend-icon-striped-color.png',
                                fallback: 'A',
                            }}
                        />
                    </div>
                </AndsendAssistantRuntimeProvider>
            </div>
        </div>
    );
};

export default HomePage;

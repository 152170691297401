import { FC, useEffect, useRef, useState } from 'react';
import { OnboardingHeader } from './components/onboarding-header';
import { TypingIndicator } from './components/typing-indicator';
import FirstPlaybookCard from './components/first-playbook-card';
import { ArrowRight, Clock } from 'lucide-react';
import { motion, AnimatePresence } from 'motion/react';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { Button } from '@shadcn/ui/components/ui/button';
import { FeedOnboardingProvider, useFeedOnboarding } from './hooks/use-feed-onboarding';
import { CardContent } from '@shadcn/ui/components/ui/card';
import { Card } from '@shadcn/ui/components/ui/card';
import { useOnboardingStage } from './hooks/use-onboarding-stage';
import { OnboardingIntroCard } from './components/onboarding-intro-card';

export const OnboardingFeedPage: FC = () => {
    return (
        <FeedOnboardingProvider>
            <OnboardingFeedPageComponent />
        </FeedOnboardingProvider>
    );
};

export const OnboardingFeedPageComponent: FC = () => {
    const {
        fetchLinkedinConversations,
        analyzeWebsite,
        conversationAnalysis,
        steps,
        setupCompleted,
        onboardingStepsComplete,
    } = useFeedOnboarding();
    const { setStage, isIntro, isPlaybook } = useOnboardingStage();
    const [startIntro, setStartIntro] = useState(false);
    const navigate = useNavigate();
    const analyzeWebsiteCompleted = steps.analyzeWebsite.status === 'completed';
    const fetchLinkedinConversationsCompleted = steps.fetchLinkedinConversations.status === 'completed';

    useEffect(() => {
        setTimeout(() => {
            setStartIntro(true);
        }, 4000);
    }, []);

    useEffect(() => {
        // Uncomment this to disable fetching LinkedIn conversations in localhost
        // if (window?.location?.hostname === 'localhost') {
        //     return;
        // }

        if (setupCompleted) {
            fetchLinkedinConversations();
        }
    }, [setupCompleted]);

    useEffect(() => {
        if (setupCompleted) {
            analyzeWebsite();
        }
    }, [setupCompleted]);

    useEffect(() => {
        if (fetchLinkedinConversationsCompleted && analyzeWebsiteCompleted) {
            conversationAnalysis();
        }
    }, [fetchLinkedinConversationsCompleted, analyzeWebsiteCompleted]);

    return (
        <div className="h-full w-full overflow-y-auto">
            <div className="flex flex-col items-center w-full max-w-3xl mx-auto p-4 gap-6 pt-8">
                <div className="flex flex-col items-start w-full">
                    <h1 className="text-3xl font-semibold text-gray-900 text-left ">
                        Supercharging your customer relationships
                    </h1>
                </div>

                <AnimatePresence mode="wait">
                    <motion.div
                        key="header-section"
                        initial={{ opacity: 1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.6 }}
                        className="w-full"
                    >
                        <OnboardingHeader />
                    </motion.div>
                </AnimatePresence>

                {isIntro && startIntro && <OnboardingIntroCard onContinue={() => setStage('playbook')} />}

                {isPlaybook && (
                    <PlaybookStage
                        analyzeWebsiteCompleted={analyzeWebsiteCompleted}
                        onContinue={() => navigate(paths.NEW.ONBOARDING_PATHS.ACTION_FEED_FINAL)}
                    />
                )}

                {!onboardingStepsComplete && <OnboardingTakingTooLongWarning appearAfterMs={60_000 * 3} />}

                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 1 }}
                        animate={{ opacity: onboardingStepsComplete ? 0.7 : 1 }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.8 }}
                        className="w-full"
                    >
                        {!onboardingStepsComplete && <SettingUpExperience />}
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

const PlaybookStage: FC<{
    analyzeWebsiteCompleted: boolean;
    onContinue: () => void;
}> = ({ analyzeWebsiteCompleted, onContinue }) => {
    const { onboardingStepsComplete } = useFeedOnboarding();
    return (
        <div className="pb-8">
            {analyzeWebsiteCompleted && (
                <motion.div
                    initial={{ x: -200, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="w-full"
                >
                    <FirstPlaybookCard displayContacts={onboardingStepsComplete} />
                    {onboardingStepsComplete && (
                        <div className="mt-4 flex justify-end">
                            <Button onClick={onContinue}>Got it</Button>
                        </div>
                    )}
                </motion.div>
            )}
        </div>
    );
};

const OnboardingTakingTooLongWarning: FC<{ appearAfterMs: number }> = ({ appearAfterMs }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setTimeout(turnOnOnboardingTakingTooLongWarning, appearAfterMs);
    }, []);

    const turnOnOnboardingTakingTooLongWarning = () => {
        if (!isVisible) {
            setIsVisible(true);
        }
    };

    if (!isVisible) {
        return null;
    }

    return (
        <Card className="border-gray-200 shadow-sm w-full">
            <CardContent className="p-4">
                <div className="flex items-start gap-3">
                    <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center flex-shrink-0">
                        <Clock className="h-4 w-4 text-blue-600" />
                    </div>

                    <div>
                        <h3 className="text-base font-medium mb-1">Setup is taking a bit longer than expected</h3>
                        <p className="text-sm text-gray-600 mb-3">
                            You can keep waiting or proceed without everything being prepared.
                        </p>

                        <Button asChild size="sm" className="w-full sm:w-auto">
                            <Link to={paths.NEW.ACTIONS} reloadDocument>
                                Continue to Action Feed
                                <ArrowRight className="h-3.5 w-3.5 ml-1" />
                            </Link>
                        </Button>
                        <p className="text-xs text-gray-500 mt-2">
                            The best way to get started is to add a few contacts from the <b>LinkedIn</b> section in the
                            sidebar. An action will appear as soon as you add a contact. Remember to check and update
                            your playbooks and add more contacts as you go.
                        </p>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

const SettingUpExperience: FC = () => {
    return (
        <div className="flex flex-col w-full items-center pb-8">
            <div className="flex gap-2 text-md text-gray-500">
                <TypingIndicator />
                <p>Setting up your experience...</p>
            </div>
        </div>
    );
};

export default OnboardingFeedPage;

import { useApolloClient, useQuery } from '@apollo/client';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Button } from '@shadcn/ui/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { GET_PLAYBOOK_BY_GROUP_ID, GET_PLAYBOOKS } from '@zaplify/graphql';
import { BookHeart, Copy, Ellipsis, Info, Pen, Trash } from 'lucide-react';
import { FC, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from '../../../../../routes/paths';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { usePlaybookImages } from '../../hooks/use-playbook-images';
import { useMutation } from '@tanstack/react-query';
import { useSdk } from '../../../../sdk/use-sdk';

interface PlaybookCardProps {
    groupId: string;
}

export const PlaybookCard: FC<PlaybookCardProps> = ({ groupId }) => {
    const client = useApolloClient();
    const { toast } = useToast();
    const navigate = useNavigate();
    const { unusedImgSrc } = usePlaybookImages();
    const { data: playbookData } = useQuery(GET_PLAYBOOK_BY_GROUP_ID, {
        variables: {
            groupId: groupId,
        },
    });

    const { data: allPlaybooksData } = useQuery(GET_PLAYBOOKS, { variables: { name: '%' } });

    const allPlaybooks = useMemo(() => allPlaybooksData?.Groups, [allPlaybooksData]);
    const {
        playbook: {
            createGroupWithAssistantSettings: createGroupMutation,
            updateGroup: updateGroupMutation,
            deleteGroup: deleteGroupMutation,
        },
    } = useSdk();

    const { mutateAsync: updateGroup } = useMutation(updateGroupMutation());
    const { mutateAsync: createGroup } = useMutation(createGroupMutation());
    const { mutateAsync: deleteGroup } = useMutation(deleteGroupMutation());

    const playbook = useMemo(() => playbookData?.Groups[0], [playbookData]);
    const numberOfContacts = useMemo(() => playbook?._GroupToUserContacts?.length, [playbook]);
    const isDefault = useMemo(() => playbook?.default, [playbook]);

    const handleSetDefault = async () => {
        await updateGroup({
            id: groupId,
            updatedGroup: { default: true },
        });

        toast({
            title: `Default playbook set to "${playbook.name}"`,
            description: 'Newly added contacts will by default be assigned to this playbook',
            variant: 'success',
        });
        await client.refetchQueries({
            // include: [GET_GROUPS, GET_PLAYBOOKS, GET_PLAYBOOK_BY_GROUP_ID],
            updateCache: (cache) => cache.evict({ fieldName: 'Groups' }),
        });
    };

    const handleClone = async () => {
        await createGroup({
            group: {
                name: `${playbook.name} (copy)`,
                imgSrc: unusedImgSrc,
                default: false,
            },
            assistantSettings: {
                languages: playbook.AssistantSetting.languages,
                purpose: playbook.AssistantSetting.purpose,
                tone: playbook.AssistantSetting.tone,
                context: playbook.AssistantSetting.context,
                defaultChannel: playbook.AssistantSetting.defaultChannel,
            },
        });
        toast({
            title: `Playbook copied`,
            description: `A copy of ${playbook.name} has been created`,
            variant: 'success',
        });
        await client.refetchQueries({
            // include: [GET_GROUPS, GET_PLAYBOOKS, GET_PLAYBOOK_BY_GROUP_ID],
            updateCache: (cache) => cache.evict({ fieldName: 'Groups' }),
        });
    };

    const handleDelete = async () => {
        const defaultGroupName = allPlaybooks.find((group) => group.default).name;
        let subMessage: string | undefined;
        if (numberOfContacts && numberOfContacts > 0 && defaultGroupName) {
            subMessage = `Your default playbook "${defaultGroupName}" was assigned to ${numberOfContacts} contacts`;
        }
        await deleteGroup({ groupId });
        toast({
            title: 'Playbook deleted',
            description: subMessage,
            variant: 'success',
        });
        await client.refetchQueries({
            // include: [GET_GROUPS, GET_PLAYBOOKS, GET_PLAYBOOK_BY_GROUP_ID],
            updateCache: (cache) => cache.evict({ fieldName: 'Groups' }),
        });
    };

    return (
        <div className="flex flex-col rounded-2xl w-64 h-60 bg-background-primary">
            <div
                className="relative flex justify-end cursor-pointer"
                onClick={() => navigate('/new' + paths.PLAYBOOKS + `/${playbook.id}`)}
            >
                <img
                    src={playbook?.imgSrc}
                    alt="playbook"
                    className="w-full h-40 object-cover rounded-t-2xl"
                    onError={(e) => {
                        e.currentTarget.src = unusedImgSrc;
                    }}
                />
                {isDefault && (
                    <div className="absolute p-2">
                        <Tooltip>
                            <TooltipTrigger>
                                <Badge variant="gray" className="flex gap-1 w-18">
                                    Default
                                    <Info className="w-3 h-3" />
                                </Badge>
                            </TooltipTrigger>
                            <TooltipContent>
                                Newly added contacts will by default be assigned to this playbook
                            </TooltipContent>
                        </Tooltip>
                    </div>
                )}
            </div>
            <div className="flex flex-row p-4 border rounded-b-2xl justify-between max-w-full">
                <div className="flex flex-grow flex-col gap-1 max-w-[80%]">
                    <h3 className="flex flex-grow text-text-primary font-medium text-md break-all line-clamp-1">
                        <Link className="flex-grow hover:underline" to={`/new${paths.PLAYBOOKS}/${playbook?.id}`}>
                            {playbook?.name}
                        </Link>
                    </h3>
                    <p className="text-text-tertiary text-sm">
                        <Link className="hover:underline" to={`/new${paths.CONTACTS}?groupId=${groupId}`}>
                            {numberOfContacts} contacts
                        </Link>
                    </p>
                </div>
                <div className="flex items-center">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="icon">
                                <Ellipsis className="w-4 h-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem onClick={handleSetDefault} disabled={isDefault}>
                                <BookHeart className="stroke-[1]" />
                                {isDefault ? 'Is default' : 'Set as default'}
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => navigate('/new' + paths.PLAYBOOKS + `/${playbook.id}`)}>
                                <Pen className="stroke-[1]" />
                                Edit
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={handleClone}>
                                <Copy className="stroke-[1]" />
                                Clone
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                className="text-destructive focus:text-destructive"
                                onClick={handleDelete}
                                disabled={isDefault}
                            >
                                <Trash className="stroke-[1]" />
                                Delete
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
        </div>
    );
};

import { HubSpotDto } from '@zaplify/hubspot';
import { PipeDriveDto } from '@zaplify/pipedrive';
import { WebhookDto } from '@zaplify/webhooks';
import { CreateProspectDto, CreateProspectDataDto } from './create-prospect.dto';
import { Source } from '../source.enum';

export enum ProspectOccupiedStatus {
    AVAILABLE = 'AVAILABLE',
    QUEUED_FOR_RELEASE = 'QUEUED_FOR_RELEASE',
    OCCUPIED = 'OCCUPIED',
}

export enum ProspectLinkedinProfileVerifiedStatus {
    verified = 'verified',
    profile_not_found = 'profile_not_found',
}

export interface AppsDto {
    hubSpot?: HubSpotDto;
    webhooks?: WebhookDto;
    pipedrive?: PipeDriveDto;
}

export class ProspectDataExperienceDto {
    id: string;
    organizationName?: string | null | undefined;
    organizationLinkedinUrl?: string;
    organizationDomain?: string;
    organizationIndustry?: string;
    organizationId?: string;
    organizationLinkedinId?: string;
    organizationSize?: string;
    organizationImageUrl?: string;
    organizationDescription?: string;
    occupationTitle?: string | null | undefined;
    occupationStartDate?: string | null | undefined;
    occupationEndDate?: string | null | undefined;
    occupationRole?: string;
    occupationSubRole?: string;
    occupationLevels?: string[];
    occupationDescription?: string;
    createdAt?: Date;
    updatedAt?: Date;

    constructor(iProspectDataExperienceDto: ProspectDataExperienceDto) {
        this.id = iProspectDataExperienceDto.id;
        this.organizationName = iProspectDataExperienceDto.organizationName;
        this.organizationLinkedinUrl = iProspectDataExperienceDto.organizationLinkedinUrl;
        this.organizationDomain = iProspectDataExperienceDto.organizationDomain;
        this.organizationId = iProspectDataExperienceDto.organizationId;
        this.organizationLinkedinId = iProspectDataExperienceDto.organizationLinkedinId;
        this.organizationSize = iProspectDataExperienceDto.organizationSize;
        this.organizationIndustry = iProspectDataExperienceDto.organizationIndustry;
        this.organizationImageUrl = iProspectDataExperienceDto.organizationImageUrl;
        this.organizationDescription = iProspectDataExperienceDto.organizationDescription;
        this.occupationTitle = iProspectDataExperienceDto.occupationTitle;
        this.occupationStartDate = iProspectDataExperienceDto.occupationStartDate;
        this.occupationEndDate = iProspectDataExperienceDto.occupationEndDate;
        this.occupationRole = iProspectDataExperienceDto.occupationRole;
        this.occupationSubRole = iProspectDataExperienceDto.occupationSubRole;
        this.occupationLevels = iProspectDataExperienceDto.occupationLevels;
        this.occupationDescription = iProspectDataExperienceDto.occupationDescription;
        this.createdAt = iProspectDataExperienceDto.createdAt;
        this.updatedAt = iProspectDataExperienceDto.updatedAt;
    }

    static fromDocument(document: ProspectDataExperienceDto) {
        const prospectDataExperienceDto = new ProspectDataExperienceDto(document);
        return prospectDataExperienceDto;
    }
}

export interface ProspectDataEducationDto {
    schoolName?: string;
    schoolLinkedinUrl?: string;
    schoolDomain?: string;
    majors?: string[];
    startDate?: string;
    endDate?: string;
}

export interface ProspectDataWithPersonId extends ProspectDataDto {
    personId: string;
}

export interface IPhoneNumber {
    number: string;
    type?: string;
}
export interface PhoneNumberDto extends IPhoneNumber {
    number: string;
    type?: string;
}
export interface LinkedinProfileVerificationDto {
    status: ProspectLinkedinProfileVerifiedStatus;
    checkedAt: Date;
}

/**
 * This interface reflects class ProspectDataDto in package @zaplify/cqrs
 * Most of the fields are marked as optional, but as heard from backend team
 * "most of the data is always there
 */
export class ProspectDataDto {
    //Personal level
    firstName: string;
    lastName: string;
    fullName: string;
    gender?: string;
    location?: string;
    locationCountry?: string;
    locationRegion?: string;
    locationCity?: string;
    email?: string;
    phoneNumbers?: IPhoneNumber[];
    birthYear?: string;
    birthDate?: string;

    interests?: string[];
    skills?: string[];

    // Social Media
    facebookProfileUrl?: string;
    twitterProfileUrl?: string;
    githubProfileUrl?: string;

    linkedinProfileUrl?: string;
    linkedinProfileVerification?: LinkedinProfileVerificationDto;
    linkedinUserId?: string;
    linkedinProfileImgUrl?: string;
    linkedinNumberOfConnections?: number;
    linkedinHeadline?: string;
    linkedinDescription?: string;

    //Occupation level
    avatarImgUrl?: string;

    education?: ProspectDataEducationDto[];
    experiences?: ProspectDataExperienceDto[];
    industry?: string;
    occupationTitle?: string;
    occupationRole?: string;
    occupationSubRole?: string;
    occupationLevels?: string[];
    occupationDuration?: string;
    occupationStartDate?: string;

    //Organization level
    organizationName: string;
    organizationImageUrl?: string;
    organizationDescription?: string;
    organizationId?: string;
    organizationLinkedinId?: string;
    organizationIndustry?: string;
    organizationSize?: string;
    organizationDomain?: string;
    organizationLinkedinUrl?: string;

    personId?: string;

    constructor(dto: ProspectDataDto) {
        // Add all fields
        this.firstName = dto.firstName;
        this.lastName = dto.lastName;
        this.fullName = dto.fullName;
        this.gender = dto.gender;
        this.location = dto.location;
        this.locationCountry = dto.locationCountry;
        this.locationRegion = dto.locationRegion;
        this.locationCity = dto.locationCity;
        this.email = dto.email;
        this.phoneNumbers = dto.phoneNumbers || [];
        this.birthYear = dto.birthYear;
        this.birthDate = dto.birthDate;
        this.interests = dto.interests;
        this.skills = dto.skills;
        this.facebookProfileUrl = dto.facebookProfileUrl;
        this.twitterProfileUrl = dto.twitterProfileUrl;
        this.githubProfileUrl = dto.githubProfileUrl;
        this.linkedinProfileUrl = dto.linkedinProfileUrl;
        this.linkedinUserId = dto.linkedinUserId;
        this.linkedinProfileVerification = dto.linkedinProfileVerification;
        this.linkedinProfileImgUrl = dto.linkedinProfileImgUrl;
        this.linkedinNumberOfConnections = dto.linkedinNumberOfConnections;
        this.linkedinHeadline = dto.linkedinHeadline;
        this.linkedinDescription = dto.linkedinDescription;
        this.avatarImgUrl = dto.avatarImgUrl;
        this.education = dto.education || [];
        this.experiences = dto.experiences || [];
        this.industry = dto.industry;
        this.occupationTitle = dto.occupationTitle;
        this.occupationRole = dto.occupationRole;
        this.occupationSubRole = dto.occupationSubRole;
        this.occupationLevels = dto.occupationLevels;
        this.occupationDuration = dto.occupationDuration;
        this.occupationStartDate = dto.occupationStartDate;
        this.organizationImageUrl = dto.organizationImageUrl;
        this.organizationDescription = dto.organizationDescription;
        this.organizationId = dto.organizationId;
        this.organizationLinkedinId = dto.organizationLinkedinId;
        this.organizationIndustry = dto.organizationIndustry;
        this.organizationName = dto.organizationName;
        this.organizationSize = dto.organizationSize;
        this.organizationDomain = dto.organizationDomain;
        this.organizationLinkedinUrl = dto.organizationLinkedinUrl;
        this.personId = dto.personId;
    }

    static fromDocument(iProspectData: ProspectDataDto) {
        const prospectData = new ProspectDataDto(iProspectData);
        return prospectData;
    }
    static fromCreateProspectDataDto(createProspectDataDto: CreateProspectDataDto) {
        const prospectDataDto = new ProspectDataDto(createProspectDataDto);
        return prospectDataDto;
    }

    static isEmailEnriching(data: ProspectDataDto) {
        return data.email === '...';
    }

    static hasEmail(data: ProspectDataDto) {
        return data.email && data.email !== 'none' && !this.isEmailEnriching(data);
    }

    static getStringSummary(data: ProspectDataDto): string {
        const parts: string[] = [];

        // Basic personal details
        if (data.fullName) {
            parts.push(`Full Name: ${data.fullName}`);
        }
        if (data.linkedinHeadline) {
            parts.push(`Headline: ${data.linkedinHeadline}`);
        }
        if (data.location) {
            parts.push(`Location: ${data.location}`);
        }
        if (data.linkedinDescription) {
            parts.push(`Summary: ${truncateText(data.linkedinDescription, 100)}`);
        }

        // Contact information
        if (data.email) {
            parts.push(`Email: ${data.email}`);
        }
        if (data.phoneNumbers?.length) {
            parts.push(`Phone Numbers: ${data.phoneNumbers.map((p) => `${p.number} (${p.type || 'N/A'})`).join(', ')}`);
        }

        // Current position
        if (data.organizationName || data.occupationTitle) {
            parts.push('Current Position:');
            if (data.occupationTitle) {
                parts.push(`  • Role: ${data.occupationTitle}`);
            }
            if (data.organizationName) {
                parts.push(`  • Company: ${data.organizationName}`);
            }
            if (data.organizationIndustry) {
                parts.push(`  • Industry: ${data.organizationIndustry}`);
            }
            if (data.organizationSize) {
                parts.push(`  • Company Size: ${data.organizationSize}`);
            }
            if (data.organizationDescription) {
                parts.push(`  • Company Description: ${truncateText(data.organizationDescription, 30)}`);
            }
        }

        // Experience history
        if (data.experiences?.length) {
            parts.push('Past Experiences:');
            data.experiences.slice(0, 3).forEach((exp) => {
                const expParts: string[] = [];
                if (exp.occupationTitle) {
                    expParts.push(`  • Role: ${exp.occupationTitle}`);
                }
                if (exp.organizationName) {
                    expParts.push(`  • Company: ${exp.organizationName}`);
                }
                if (exp.occupationDescription) {
                    expParts.push(`  • Description: ${truncateText(exp.occupationDescription, 100)}`);
                }
                if (expParts.length) {
                    parts.push('-\n' + expParts.join('\n'));
                }
            });
        }

        // Education
        if (data.education?.length) {
            parts.push('Education:');
            data.education.slice(0, 2).forEach((edu) => {
                parts.push(`- Institution: ${edu.schoolName}, Majors: ${edu.majors?.join(', ') || 'N/A'}`);
            });
        }

        // Skills and Interests
        if (data.skills?.length) {
            parts.push(`Skills: ${data.skills.slice(0, 5).join(', ')}${data.skills.length > 5 ? '...' : ''}`);
        }
        if (data.interests?.length) {
            parts.push(`Interests: ${data.interests.slice(0, 5).join(', ')}${data.interests.length > 5 ? '...' : ''}`);
        }

        return parts.join('\n');
    }
}

function truncateText(text: string, maxWords: number): string {
    const words = text.split(' ');
    if (words.length > maxWords) {
        return words.slice(0, maxWords).join(' ').replace('\n', ' ') + ' ...';
    }
    return text.replace('\n', ' ');
}

export class CompanyDto {
    name: string;
    domain: string;
    industry: string;
    linkedinUrl: string;
    size: string;

    constructor(iCompanyDto: CompanyDto) {
        this.name = iCompanyDto.name;
        this.domain = iCompanyDto.domain;
        this.industry = iCompanyDto.industry;
        this.linkedinUrl = iCompanyDto.linkedinUrl;
        this.size = iCompanyDto.size;
    }
}

// export interface ProspectsByCompanyDto {
//     [companyId: string]: string[];
// }

export class ProspectDto implements CreateProspectDto {
    id: string;
    createdByUserId: string;
    userOrganizationId: string;
    data: ProspectDataDto;
    sourceId?: string;
    source: Source;
    /** @deprecated */
    owner?: ProspectOwnerOldDto;
    associatedPersonIds?: string[];
    isReviewed?: boolean;
    isRefreshing?: boolean;
    refreshedAt?: Date | null;
    createdAt?: Date | null;
    occupiedStatus: ProspectOccupiedStatus;

    constructor(iProspect: ProspectDto) {
        this.id = iProspect.id;
        this.userOrganizationId = iProspect.userOrganizationId;
        this.createdByUserId = iProspect.createdByUserId;
        this.data = ProspectDataDto.fromDocument(iProspect.data);
        this.source = iProspect.source;
        this.sourceId = iProspect.sourceId;
        this.owner = iProspect.owner ? new ProspectOwnerOldDto(iProspect.owner) : undefined;
        this.associatedPersonIds = iProspect.associatedPersonIds;
        this.createdAt = iProspect.createdAt;
        this.isReviewed = iProspect.isReviewed;
        this.isRefreshing = iProspect.isRefreshing;
        this.refreshedAt = iProspect.refreshedAt;
        this.occupiedStatus = iProspect.occupiedStatus;
    }

    static fromDocument(iProspect: ProspectDto) {
        const prospectDto = new ProspectDto(iProspect);
        return prospectDto;
    }

    static fromCreateProspectDto(createProspectDto: CreateProspectDto & { id: string; owner?: ProspectOwnerOldDto }) {
        const prospectDto = new ProspectDto({ ...createProspectDto, occupiedStatus: ProspectOccupiedStatus.OCCUPIED });
        return prospectDto;
    }
}

export class ProspectWithOwnersDto extends ProspectDto {
    owners: ProspectOwnerDto[];

    constructor(iProspect: ProspectWithOwnersDto) {
        super(iProspect);
        this.owners = iProspect.owners;
    }
}

export interface OwnersDto {
    prospectIdsMap: Record<string, ProspectOwnerDto[]>;
    personIdsMap: Record<string, ProspectOwnerDto[]>;
    linkedinUrlsMap: Record<string, ProspectOwnerDto[]>;
    linkedinMemberIdsMap: Record<string, ProspectOwnerDto[]>;
}

export interface ProspectOwnerDto {
    userId: string;
    fullName: string;
}

export class ProspectOwnerOldDto {
    /** @deprecated */
    name: string;
    /** @deprecated */
    userId?: string;

    constructor(iProspectOwnerOldDto: ProspectOwnerOldDto) {
        this.name = iProspectOwnerOldDto?.name;
        this.userId = iProspectOwnerOldDto?.userId;
    }
}

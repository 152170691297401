import React, { useEffect, useMemo, useState } from 'react';
import { cn } from '@shadcn/ui/lib/utils';
import { motion } from 'motion/react';
import { Linkedin, Loader2 } from 'lucide-react';
import { useLinkedinSearch, LinkedinPerson } from '../../../pages/import/linkedin/hooks/use-linkedin-search';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { AddContactButton } from '../../../components/buttons/add-contact-button';
import { useDebounce, useDebounceNew } from '../../../hooks/use-debounce';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ContactAvatar } from '../../../components/contact-avatar';

export const ConversationLinkedinSearch: React.FC<{
    queryString: string;
    excludeMemberIds: string[];
}> = ({ queryString, excludeMemberIds = [] }) => {
    const { conversationsSearchLiNetwork } = useFlags();

    const { debouncedValue: debouncedQuery, isPending } = useDebounceNew(queryString, 800, {
        minLength: 2,
        initialValue: false,
    });

    if (!conversationsSearchLiNetwork || queryString.length < 2) {
        return null;
    }

    if (debouncedQuery?.length < 1) {
        return null;
    } else {
        return (
            <ConversationLinkedinSearchContent
                queryString={debouncedQuery}
                isPending={isPending}
                excludeMemberIds={excludeMemberIds}
            />
        );
    }
};

const ConversationLinkedinSearchContent = ({
    queryString,
    isPending,
    excludeMemberIds,
}: {
    queryString: string;
    isPending: boolean;
    excludeMemberIds: string[];
}) => {
    const { persons, isLoading, isError } = useLinkedinSearch(queryString, 'connections', { debounceTime: 800 });

    const filteredPersons = useMemo(
        () => persons.filter((person) => !excludeMemberIds.includes(person.memberId)),
        [persons, excludeMemberIds]
    );

    if (!queryString || queryString.length === 0) {
        return null;
    }

    if (isPending) {
        return (
            <div className="flex items-center justify-center p-4 overflow-y-auto">
                <Loader2 className="h-5 w-5 animate-spin text-primary" />
            </div>
        );
    }

    if ((filteredPersons.length === 0 && !isLoading) || isError) {
        return null;
    }

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center p-4">
                    <Loader2 className="h-5 w-5 animate-spin text-primary" />
                </div>
            ) : (
                <>
                    {/* LinkedIn Network Section */}
                    <div className="flex items-center py-4">
                        <div className="h-px flex-1 bg-border"></div>
                        <span className="px-3 text-sm text-text-tertiary font-medium">LinkedIn Network</span>
                        <div className="h-px flex-1 bg-border"></div>
                    </div>
                    <div className="flex flex-col max-h-full w-full gap-1">
                        {filteredPersons.length > 0 ? (
                            filteredPersons
                                .slice(0, 20)
                                .map((person) => (
                                    <LinkedinConnectionCard
                                        key={person.memberId}
                                        id={person.memberId}
                                        name={`${person.firstName} ${person.lastName}`}
                                        headline={person.headline || ''}
                                        imageUrl={person.profilePicture || ''}
                                    />
                                ))
                        ) : (
                            <div className="flex items-center justify-center p-4">
                                <span className="text-sm text-text-tertiary">No results found</span>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

interface LinkedinConnectionCardProps {
    id: string;
    name: string;
    headline: string;
    imageUrl: string;
}

const LinkedinConnectionCard: React.FC<LinkedinConnectionCardProps> = ({ id, name, headline, imageUrl }) => {
    return (
        <motion.div
            layout
            initial={false}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -300 }}
            transition={{
                layout: { type: 'spring', stiffness: 300, damping: 30 },
                duration: 0.5,
            }}
        >
            <div
                className={cn(
                    'group',
                    'flex gap-2 items-center text-sm',
                    'rounded-md',
                    'p-2',
                    'hover:bg-background-secondary-hover'
                )}
            >
                <div className="flex-shrink-0">
                    <ContactAvatar avatarUrl={imageUrl} fullName={name} size="md" />
                </div>
                <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-foreground truncate">{name}</p>
                    <p className="text-xs text-text-tertiary truncate">{headline}</p>
                </div>
                <AddContactButton
                    source={ContactSource.LinkedinImport}
                    startIcon={<Linkedin className="w-4 h-4" />}
                    linkedinUserId={id}
                    variant="outline"
                    size="sm"
                    alwaysDropdown={true}
                    // className="flex-shrink-0 rounded-full bg-primary hover:bg-primary/90 text-primary-foreground [&>button]:w-7 [&>button]:h-7"
                />
            </div>
        </motion.div>
    );
};

import { BarChart, BookOpen, BookUser, Bot, MessagesSquare, Plus, Target } from 'lucide-react';

import { NavMain } from './nav-main';
import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarRail } from '@shadcn/ui/components/ui/sidebar';
import { NavTop } from './nav-top';
import { NavFooter } from './nav-footer';
import React, { useEffect, useState } from 'react';
import { useGlobalSearchDialog } from '../dialogs/global-search-dialog';
import { paths } from '../../../routes/paths';
import { SearchSidebarBox } from '../dialogs/global-search/search-form';
import { PlanCode } from '@zaplify/subscriptions';
import { useAuth } from '../../providers/authentication-provider';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { useLocation } from 'react-router-dom';
import { cn } from '@shadcn/ui/lib/utils';
import { useOnboardingStage } from '../../pages/action-feed/onboarding/hooks/use-onboarding-stage';

const createNavMainData = (
    listImportEnabled: boolean,
    newCsvImport: boolean,
    useActionFeed: boolean,
    showAgentInSidebar: boolean
) => [
    ...(showAgentInSidebar
        ? [
              {
                  title: 'Your Agent (Beta)',
                  url: '/new/home',
                  icon: Bot,
                  items: [],
              },
          ]
        : []),
    {
        title: 'Actions',
        url: useActionFeed ? paths.NEW.ACTION_FEED : paths.NEW.ACTIONS,
        icon: Target,
        items: [],
        isActive: false,
        asLink: true,
    },
    {
        title: 'Playbooks',
        url: '/new' + paths.PLAYBOOKS,
        icon: BookOpen,
        className: 'playbooks-nav-item',
        items: [],
        hideOnMobile: true,
    },
    {
        title: 'Conversations',
        url: paths.NEW.MESSAGES,
        icon: MessagesSquare,
        items: [],
        isActive: false,
        asLink: false,
    },
    {
        title: 'Contacts',
        url: paths.NEW.CONTACTS,
        icon: BookUser,
        items: [],
    },
    {
        title: 'Add contacts',
        url: `/new/discover`,
        icon: Plus,
        items: [
            {
                title: 'Discover',
                url: paths.NEW.DISCOVER_PATHS.SUGGESTIONS,
            },
            {
                title: 'LinkedIn',
                url: '/new/discover/import/linkedin',
                hideOnMobile: true,
            },
            ...(listImportEnabled
                ? newCsvImport
                    ? [
                          {
                              title: 'List import',
                              url: '/new/discover/import/list',
                              hideOnMobile: true,
                          },
                      ]
                    : [
                          {
                              title: 'List import',
                              url: paths.IMPORT_PATHS.PROSPECTS,
                              hideOnMobile: true,
                          },
                      ]
                : []),
        ],
    },
    {
        title: 'Statistics',
        url: '/new/statistics',
        icon: BarChart,
        items: [],
    },
];

export function AppSidebarV2({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const { openGlobalSearch } = useGlobalSearchDialog();
    const { newCsvImport, useActionFeed, showAgentInSidebar } = useFlags();
    const isMobile = useIsMobile();
    const { sidebarState, toggleSidebar } = useSidebars();
    const location = useLocation();
    const isOnboarding = React.useMemo(
        () => location.pathname === paths.NEW.ONBOARDING_PATHS.ACTION_FEED,
        [location.pathname]
    );
    const isOnboardingFinal = React.useMemo(
        () => location.pathname === paths.NEW.ONBOARDING_PATHS.ACTION_FEED_FINAL,
        [location.pathname]
    );

    useEffect(() => {
        if (!isOnboarding) {
            return;
        }

        if (sidebarState.main) {
            toggleSidebar('main');
        }
    }, [isOnboarding]);

    useEffect(() => {
        if (!isOnboardingFinal) {
            return;
        }

        if (!sidebarState.main) {
            toggleSidebar('main');
        }
    }, [isOnboardingFinal]);

    const {
        authState: {
            tokenResult: {
                claims: { plan },
            },
        },
    } = useAuth();

    const listImportEnabled = React.useMemo(() => plan !== PlanCode.FREE, [plan]);
    const navMainData = React.useMemo(
        () => createNavMainData(listImportEnabled, newCsvImport, useActionFeed, showAgentInSidebar),
        [listImportEnabled, newCsvImport, useActionFeed, showAgentInSidebar]
    );

    React.useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                openGlobalSearch();
            }
        };

        document.addEventListener('keydown', down);
        return () => document.removeEventListener('keydown', down);
    }, []);

    return (
        <div className={cn('relative', { 'opacity-70 pointer-events-none': isOnboarding })}>
            <Sidebar collapsible="icon" {...props}>
                <SidebarHeader>
                    <NavTop />
                    <SearchSidebarBox
                        onFocus={() => {
                            openGlobalSearch();
                            if (isMobile && sidebarState.main) {
                                toggleSidebar('main');
                            }
                        }}
                    />
                </SidebarHeader>
                <SidebarContent>
                    <NavMain items={navMainData} />
                </SidebarContent>
                <SidebarFooter>
                    <NavFooter />
                </SidebarFooter>
                <SidebarRail className="mt-5 mb-5 -mr-2" />
            </Sidebar>
        </div>
    );
}

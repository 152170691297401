import React, { createContext, useContext, useState, useCallback, ReactNode, useMemo } from 'react';
import { getZaplifySdk, LinkedinConversation } from '@zaplify/sdk';
import { useAuth } from '../../../../providers/authentication-provider';
import { useLiConversationsImport } from '../../../../hooks/linkedin/use-li-conversations-import';
import { ConversationMessageParsed } from '@zaplify/web-extension-shared';
import { ConnectionStatus } from '@zaplify/campaigns';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { useSdk } from '../../../../sdk/use-sdk';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ProspectDataDto } from '@zaplify/prospects';
import { parseLinkedinConversationToUserActivity } from '../../../../functions/trackers';
import { ChannelProvider } from '@zaplify/channel-accounts/shared';
import { GET_PLAYBOOKS, GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK } from '@zaplify/graphql';
import { useApolloClient, useQuery as useApolloQuery } from '@apollo/client';

export interface PlaybookTemplate {
    name: string;
    tone: string;
    purpose: string;
    context: string;
    language: string;
    targetGroup?: string;
}

export type OnboardingStep = 'fetchLinkedinConversations' | 'analyzeWebsite' | 'conversationAnalysis';

export interface StepProgress {
    id: OnboardingStep;
    status: 'pending' | 'in-progress' | 'completed';
    title: string;
    description: string;
    progress: number;
}

const GET_COMPANY_PROFILE = 'getCompanyProfile';

interface OnboardingContextType {
    fetchLinkedinConversations: () => Promise<void>;
    analyzeWebsite: () => Promise<void>;
    conversationAnalysis: () => Promise<void>;
    steps: Record<OnboardingStep, StepProgress>;
    setupCompleted: boolean;
    onboardingStepsComplete: boolean;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export const FeedOnboardingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { authState } = useAuth();
    const apolloClient = useApolloClient();
    const {
        channelAccount: { getMyChannelAccounts },
        userOrganization: { getOrganizationByUser },
        playbook: { updateAssistantSettings },
        onboarding: { importLinkedinAnalysis: importLinkedinAnalysisMutation },
    } = useSdk();
    const { data: channelAccounts } = useQuery(getMyChannelAccounts());
    const { data: userOrganization } = useQuery(getOrganizationByUser(authState.userId));

    const website = useMemo(
        () => userOrganization?.find((org) => org.id === authState.userOrganizationId)?.website,
        [userOrganization, authState.userOrganizationId]
    );

    const { mutateAsync: importLinkedinAnalysis } = useMutation(
        importLinkedinAnalysisMutation({
            retry: 2,
        })
    );

    const linkedinChannelAccount = useMemo(
        () => channelAccounts?.find((account) => account.provider === ChannelProvider.LINKEDIN),
        [channelAccounts]
    );

    const { data: allPlaybook } = useApolloQuery(GET_PLAYBOOKS, {
        variables: {
            name: '%',
        },
    });
    const firstPlaybook = allPlaybook?.Groups?.[0]?.AssistantSetting;

    const setupCompleted = useMemo(() => {
        return !!linkedinChannelAccount && !!firstPlaybook && !!userOrganization;
    }, [linkedinChannelAccount, firstPlaybook, userOrganization]);

    const [fetchedLiConversations, setFetchedLiConversations] = useState<
        {
            profile: {
                prospectData: ProspectDataDto;
                connectionStatus: ConnectionStatus;
            };
            connectionStatus: ConnectionStatus;
            conversation: ConversationMessageParsed[];
        }[]
    >([]);

    const { getLiConversations, fetchProfiles, getMessagesFromConversation } = useLiConversationsImport();

    const [steps, setSteps] = useState<Record<OnboardingStep, StepProgress>>({
        fetchLinkedinConversations: {
            id: 'fetchLinkedinConversations',
            title: 'Initial Network Sync',
            description: 'Syncing your LinkedIn network',
            status: 'pending',
            progress: 0,
        },
        analyzeWebsite: {
            id: 'analyzeWebsite',
            title: 'Profile Research',
            description: 'Analyzing company profile',
            status: 'pending',
            progress: 0,
        },
        conversationAnalysis: {
            id: 'conversationAnalysis',
            title: 'Conversation Analysis',
            description: 'Processing conversations',
            status: 'pending',
            progress: 0,
        },
    });

    const updateStep = useCallback(
        (
            stepId: OnboardingStep,
            status: 'pending' | 'in-progress' | 'completed',
            progress: number = status === 'completed' ? 100 : status === 'pending' ? 0 : steps[stepId].progress
        ) => {
            setSteps((prev) => ({
                ...prev,
                [stepId]: {
                    ...prev[stepId],
                    status,
                    progress,
                },
            }));
        },
        [steps]
    );

    const { mutateAsync: getCompanyProfile } = useMutation({
        mutationKey: [GET_COMPANY_PROFILE],
        mutationFn: (domain: string) => getZaplifySdk().profiles.messenger.researchCompanyByDomain(domain),
        retry: 3,
        retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    });

    const { mutateAsync: updatePlaybookSettings } = useMutation(
        updateAssistantSettings({
            onSuccess: () => {
                apolloClient.refetchQueries({
                    include: [GET_PLAYBOOKS],
                });
            },
        })
    );

    const fetchConversationsWithTimeout = useCallback(
        async (limit: number): Promise<LinkedinConversation[]> => {
            try {
                const res = await getLiConversations(undefined, undefined, limit);
                const conversations = res.conversations || [];

                return [
                    ...conversations.filter((c) => !c.conversation.isInMail),
                    ...conversations.filter((c) => c.conversation.isInMail),
                ].slice(0, limit);
            } catch (error) {
                console.error('Error fetching LinkedIn conversations:', error);
                return [];
            }
        },
        [getLiConversations]
    );

    const fetchConversationsBatch = useCallback(
        async (
            conversations: LinkedinConversation[],
            batchSize: number = 5
        ): Promise<Array<{ memberId: string; messages: ConversationMessageParsed[] }>> => {
            const results: Array<{ memberId: string; messages: ConversationMessageParsed[] }> = [];

            for (let i = 0; i < conversations.length; i += batchSize) {
                const batch = conversations.slice(i, i + batchSize);
                const batchPromises = batch.map(async (conv) => {
                    const messages = await getMessagesFromConversation(conv.conversation.id, 10);
                    return {
                        memberId: conv.prospect.memberId,
                        messages,
                    };
                });

                const batchResults = await Promise.all(batchPromises);
                results.push(...batchResults);
            }

            return results;
        },
        [getMessagesFromConversation]
    );

    const getLinkedinConversations = useCallback(
        async (limit: number = 18) => {
            const conversations = await fetchConversationsWithTimeout(limit);
            if (!conversations || !conversations.length) {
                return [];
            }
            updateStep('fetchLinkedinConversations', 'in-progress', 30);

            const memberIds = conversations.map((c) => c.prospect?.memberId).filter(Boolean);
            if (memberIds.length === 0) {
                return [];
            }

            const profiles = await fetchProfiles({ memberIds, slowFetch: false });
            if (!profiles || profiles.length === 0) {
                return [];
            }

            updateStep('fetchLinkedinConversations', 'in-progress', 60);

            const conversationMessages = await fetchConversationsBatch(conversations);

            updateStep('fetchLinkedinConversations', 'in-progress', 90);

            return profiles.map((profile) => ({
                profile,
                connectionStatus: profile.connectionStatus,
                conversation: conversationMessages
                    .filter((msg) => msg.memberId === profile.prospectData?.linkedinUserId)
                    .flatMap((msg) => msg.messages || []),
            }));
        },
        [fetchConversationsWithTimeout, fetchProfiles, fetchConversationsBatch]
    );

    const fetchLinkedinConversations = useCallback(async () => {
        try {
            updateStep('fetchLinkedinConversations', 'in-progress', 10);
            const conversations = await getLinkedinConversations(18);
            setFetchedLiConversations(conversations);
            updateStep('fetchLinkedinConversations', 'completed', 100);
        } catch (error) {
            console.error('Error in fetchLinkedinConversations:', error);
            throw error;
        }
    }, [getLinkedinConversations, updateStep]);

    const analyzeWebsite = useCallback(async () => {
        try {
            updateStep('analyzeWebsite', 'in-progress', 20);

            if (website && !firstPlaybook?.context?.startsWith('My approach:')) {
                const companyProfileResult = await getCompanyProfile(website);
                const companyProfile = companyProfileResult?.researchSummary
                    ? `My company profile:\n${companyProfileResult.researchSummary}`
                    : '';

                updateStep('analyzeWebsite', 'in-progress', 40);

                const enrichedContext = companyProfile
                    ? `My approach:\n${firstPlaybook?.context}\n\n${companyProfile}`.trim()
                    : `My approach:\n${firstPlaybook?.context}`.trim();

                updateStep('analyzeWebsite', 'in-progress', 60);

                const updatedPlaybook = {
                    ...firstPlaybook,
                    context: enrichedContext,
                };

                await updatePlaybookSettings({
                    assistantSettingsId: firstPlaybook.id,
                    updatedAssistantSettings: updatedPlaybook,
                });
                updateStep('analyzeWebsite', 'in-progress', 80);

                window.analytics?.identify({
                    purpose_of_using_zaplify: updatedPlaybook.purpose,
                    outreach_language: updatedPlaybook.language,
                });
            }

            updateStep('analyzeWebsite', 'completed', 100);
        } catch (error) {
            console.error('Error in analyzeWebsite:', error);
            throw error;
        }
    }, [getCompanyProfile, updateStep, firstPlaybook, website]);

    const conversationAnalysis = useCallback(async () => {
        try {
            updateStep('conversationAnalysis', 'in-progress', 20);

            const createdProspects = await importLinkedinAnalysis({
                profiles: fetchedLiConversations.map((c) => ({
                    profile: c.profile.prospectData,
                    activities: parseLinkedinConversationToUserActivity(
                        c.conversation,
                        linkedinChannelAccount?.LINKEDIN?.userId,
                        c.profile.connectionStatus
                    ),
                })),
                assistantId: firstPlaybook.id,
            });

            updateStep('conversationAnalysis', 'in-progress', 70);
            if (createdProspects.length < 3) {
                const notCreatedProspects = fetchedLiConversations
                    .filter(
                        (c) =>
                            c.profile.prospectData.linkedinUserId &&
                            !createdProspects.find(
                                (p) => p.data.linkedinUserId === c.profile.prospectData.linkedinUserId
                            )
                    )
                    .map((c) => c.profile.prospectData);

                await getZaplifySdk().profiles.sources.runBulkImport(
                    notCreatedProspects.slice(0, 3 - createdProspects.length),
                    ContactSource.Onboarding
                );
            }
            apolloClient.refetchQueries({
                include: [GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK],
            });
            updateStep('conversationAnalysis', 'completed', 100);
        } catch (error) {
            console.error('Error in conversationAnalysis:', error);
            throw error;
        }
    }, [linkedinChannelAccount, updateStep]);

    const fetchLinkedinConversationsCompleted = useMemo(
        () => steps.fetchLinkedinConversations.status === 'completed',
        [steps.fetchLinkedinConversations.status]
    );
    const analyzeWebsiteCompleted = useMemo(
        () => steps.analyzeWebsite.status === 'completed',
        [steps.analyzeWebsite.status]
    );
    const conversationAnalysisCompleted = useMemo(
        () => steps.conversationAnalysis.status === 'completed',
        [steps.conversationAnalysis.status]
    );

    const onboardingStepsComplete = useMemo(() => {
        return fetchLinkedinConversationsCompleted && analyzeWebsiteCompleted && conversationAnalysisCompleted;
    }, [fetchLinkedinConversationsCompleted, analyzeWebsiteCompleted, conversationAnalysisCompleted]);

    return (
        <OnboardingContext.Provider
            value={{
                fetchLinkedinConversations,
                analyzeWebsite,
                conversationAnalysis,
                steps,
                setupCompleted,
                onboardingStepsComplete,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};

export const useFeedOnboarding = () => {
    const context = useContext(OnboardingContext);
    if (context === undefined) {
        throw new Error('useOnboarding must be used within an OnboardingProvider');
    }
    return context;
};

import { makeAssistantToolUI } from '@assistant-ui/react';
import { PlaybookCard } from '../../../playbooks/components/playbook-cards/playbook-card';
import {
    GetAllPlaybooksToolArgs,
    GetAllPlaybooksToolFactory,
    GetAllPlaybooksToolResult,
    GetPlaybookByNameToolArgs,
    GetPlaybookByNameToolFactory,
    GetPlaybookByNameToolResult,
    GetPlaybookByGroupIdToolArgs,
    GetPlaybookByGroupIdToolFactory,
    GetPlaybookByGroupIdToolResult,
} from '@andsend/services/agent/shared';
import { ChevronRight } from 'lucide-react';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { cn } from '@shadcn/ui/lib/utils';

interface CardStackProps {
    cards: React.ReactNode[];
}

const CardStack = ({ cards }: CardStackProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        setCurrentIndex((prev) => (prev + 1) % cards.length);
    };

    const goToPrev = () => {
        setCurrentIndex((prev) => (prev - 1 + cards.length) % cards.length);
    };

    const getCardStatus = (index: number): 'active' | 'inactive' | 'prev' | 'next' => {
        if (index === currentIndex) return 'active';
        const relativePosition = (index - currentIndex + cards.length) % cards.length;
        if (relativePosition === 1) return 'next';
        if (relativePosition === cards.length - 1) return 'prev';
        return 'inactive';
    };

    return (
        <div className="h-[20rem] select-none p-4 pl-20 w-full">
            <div className="card-stack relative h-64">
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className={cn('absolute inset-0 transition-all duration-300 h-64 w-64', {
                            'z-30 translate-x-0 opacity-100': getCardStatus(index) === 'active',
                            'z-20 translate-x-[calc(25%)] opacity-50 scale-90 cursor-pointer hover:opacity-75 hover:scale-[0.94] [&_button]:pointer-events-none':
                                getCardStatus(index) === 'next',
                            'z-10 -translate-x-[calc(25%)] opacity-50 scale-90 cursor-pointer hover:opacity-75 hover:scale-[0.94] [&_button]:pointer-events-none':
                                getCardStatus(index) === 'prev',
                            'z-0 translate-x-0 opacity-0 pointer-events-none': getCardStatus(index) === 'inactive',
                        })}
                        onClick={() => {
                            if (getCardStatus(index) === 'next') {
                                goToNext();
                            } else if (getCardStatus(index) === 'prev') {
                                goToPrev();
                            }
                        }}
                    >
                        {card}
                    </div>
                ))}
            </div>

            <div className="-ml-44 z-50 flex justify-center gap-4">
                <Button variant="ghost" size="icon" onClick={goToPrev}>
                    <ChevronLeft className="w-6 h-6" />
                </Button>
                <div className="flex items-center gap-2">
                    <span className="text-sm text-muted-foreground">
                        {currentIndex + 1} / {cards.length}
                    </span>
                </div>
                <Button variant="ghost" size="icon" onClick={goToNext}>
                    <ChevronRight className="w-6 h-6" />
                </Button>
            </div>
        </div>
    );
};

const PlaybookCardsUI = ({
    args,
    result,
}: {
    args: GetAllPlaybooksToolArgs | GetPlaybookByNameToolArgs | GetPlaybookByGroupIdToolArgs;
    result: GetAllPlaybooksToolResult | GetPlaybookByNameToolResult | GetPlaybookByGroupIdToolResult;
}) => {
    if (!result) {
        return <div>No playbooks found</div>;
    }

    return (
        <CardStack
            cards={result.playbooks.map((playbook) => (
                <PlaybookCard key={playbook.group.id} groupId={playbook.group.id} />
            ))}
        />
    );
};

const AllPlaybooksToolUI = makeAssistantToolUI<GetAllPlaybooksToolArgs, GetAllPlaybooksToolResult>({
    toolName: GetAllPlaybooksToolFactory.name,
    render: ({ args, result }) => PlaybookCardsUI({ args, result }),
});

const PlaybookByNameToolUI = makeAssistantToolUI<GetPlaybookByNameToolArgs, GetPlaybookByNameToolResult>({
    toolName: GetPlaybookByNameToolFactory.name,
    render: ({ args, result }) => PlaybookCardsUI({ args, result }),
});

const PlaybookByIdToolUI = makeAssistantToolUI<GetPlaybookByGroupIdToolArgs, GetPlaybookByGroupIdToolResult>({
    toolName: GetPlaybookByGroupIdToolFactory.name,
    render: ({ args, result }) => PlaybookCardsUI({ args, result }),
});

export const playbookToolsUI = [AllPlaybooksToolUI, PlaybookByNameToolUI, PlaybookByIdToolUI];

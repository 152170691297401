import { FC, ReactNode, useMemo } from 'react';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { cn } from '@shadcn/ui/lib/utils';
import { useSearchParams } from 'react-router-dom';
import { BookOpen, ChevronDown } from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
    DropdownMenuLabel,
    DropdownMenuSeparator,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { Avatar, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { Group } from '@zaplify/prisma-client/frontend2';
import { useQuery } from '@apollo/client';
import { GET_ALL_USER_CONTACTS, GET_GROUPS } from '@zaplify/graphql';

interface PlaybookFilterBadgeProps {
    disabledGroupIds?: string[];
    disabledTooltip?: string;
    className?: string;
    showGroupCounts?: boolean;
    size?: 'sm' | 'md' | 'lg';
}

export const PlaybookFilterBadge: FC<PlaybookFilterBadgeProps> = ({
    disabledGroupIds = [],
    disabledTooltip = 'No suggestions available, add more contacts to this playbook to receive suggestions',
    className,
    showGroupCounts = false,
    size = 'md',
}) => {
    const { data: groupsData } = useQuery(GET_GROUPS);

    const { data: contactsData } = useQuery(GET_ALL_USER_CONTACTS, {
        skip: !showGroupCounts,
    });

    const groupCounts = useMemo(() => {
        if (!showGroupCounts) return {};
        return contactsData?.UserContacts.reduce((acc, contact) => {
            const groupId = contact._GroupToUserContacts?.[0]?.Group.id;
            if (groupId) {
                acc[groupId] = (acc[groupId] || 0) + 1;
            }
            return acc;
        }, {} as Record<string, number>);
    }, [contactsData, showGroupCounts]);

    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamGroupId = searchParams.get('groupId');

    const groups = useMemo(() => groupsData?.Groups, [groupsData]);

    if (!groups?.length || groups.length <= 1) return null;

    return (
        <DropdownMenu>
            <Tooltip delayDuration={400}>
                <TooltipTrigger asChild>
                    <DropdownMenuTrigger className="rounded-full focus:outline-none">
                        <Badge
                            variant="outline"
                            className={cn(
                                'bg-background-primary flex items-center justify-center gap-2',
                                size === 'sm' && 'h-6 w-14 text-xs',
                                size === 'md' && 'h-8 w-15 text-xs',
                                size === 'lg' && 'h-9 w-16 text-sm',
                                searchParamGroupId && 'bg-background-brand-primary text-text-primary-on-brand',
                                className
                            )}
                        >
                            <BookOpen
                                className={cn(
                                    size === 'sm' && 'w-3 h-3',
                                    size === 'md' && 'w-3.5 h-3.5',
                                    size === 'lg' && 'w-4 h-4'
                                )}
                            />
                            <ChevronDown
                                className={cn(
                                    size === 'sm' && 'w-3 h-3',
                                    size === 'md' && 'w-3.5 h-3.5',
                                    size === 'lg' && 'w-4 h-4'
                                )}
                            />
                        </Badge>
                    </DropdownMenuTrigger>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                    <p>Filter by playbook</p>
                </TooltipContent>
            </Tooltip>
            <DropdownMenuContent
                align="start"
                onCloseAutoFocus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className="max-h-[400px] overflow-y-auto"
            >
                <DropdownMenuLabel>Filter by playbook</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                    className={cn(
                        'p-2 text-ellipsis break-all',
                        !searchParamGroupId && 'bg-background-brand-primary-subtle'
                    )}
                    onClick={(e) => {
                        setSearchParams((prevParams) => {
                            prevParams.delete('groupId');
                            return prevParams;
                        });
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className="flex flex-grow items-center justify-between gap-2">
                        <div className="flex items-center justify-start gap-2">
                            <BookOpen className="w-4 h-4" />
                            <span>All playbooks</span>
                        </div>
                        {showGroupCounts && (
                            <Badge variant="blue" className="ml-2">
                                {contactsData?.totalCount?.aggregate?.count || 0}
                            </Badge>
                        )}
                    </div>
                </DropdownMenuItem>
                {groups.map((group, index) => {
                    const isDisabled = disabledGroupIds.some((groupId) => groupId === group.id);
                    const MenuItem = (
                        <DropdownMenuItem
                            key={`${group.id}-${index}`}
                            className={cn(
                                'p-2 text-ellipsis break-all',
                                searchParamGroupId === group.id && 'bg-background-brand-primary-subtle',
                                isDisabled && 'text-text-disabled focus:bg-background-primary focus:text-text-disabled'
                            )}
                            onClick={(e) => {
                                if (isDisabled) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    return;
                                }
                                setSearchParams((prevParams) => {
                                    prevParams.set('groupId', group.id);
                                    return prevParams;
                                });
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <div className="flex flex-grow items-center justify-between gap-2">
                                <div className="flex items-center justify-start gap-2">
                                    <Avatar className="h-4 w-4">
                                        <AvatarImage src={group?.imgSrc} className="scale-[1000%]" />
                                    </Avatar>
                                    <span>{group.name}</span>
                                </div>
                                {showGroupCounts && (
                                    <Badge variant="blue" className="ml-2">
                                        {groupCounts?.[group?.id] || 0}
                                    </Badge>
                                )}
                            </div>
                        </DropdownMenuItem>
                    );

                    if (isDisabled) {
                        return (
                            <Tooltip key={group.id}>
                                <TooltipTrigger asChild>{MenuItem}</TooltipTrigger>
                                <TooltipContent side="right">
                                    <p>{disabledTooltip}</p>
                                </TooltipContent>
                            </Tooltip>
                        );
                    }

                    return MenuItem;
                })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export type OnboardingStage = 'intro' | 'playbook';

/**
 * Hook to manage the onboarding stage through URL search parameters.
 * This allows for stage persistence in the URL, enabling browser history
 * navigation and shareable links with specific onboarding stages.
 *
 * @returns {Object} Object containing:
 *   - stage: The current onboarding stage
 *   - setStage: Function to change the stage
 *   - isIntro/isPlaybook/isFinal: Convenience booleans for stage checks
 */
export function useOnboardingStage() {
    const [searchParams, setSearchParams] = useSearchParams();

    const stage = (searchParams.get('stage') as OnboardingStage) || 'intro';

    const setStage = useCallback(
        (newStage: OnboardingStage) => {
            setSearchParams(
                (prev) => {
                    const updated = new URLSearchParams(prev);
                    updated.set('stage', newStage);
                    return updated;
                },
                { replace: true }
            );
        },
        [setSearchParams]
    );

    return {
        stage,
        setStage,
        isIntro: stage === 'intro',
        isPlaybook: stage === 'playbook',
    };
}

import { UseQueryOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';

export const getProspectOwnersCacheKey = (prospectId: string) => ['getProspectOwners', prospectId];

const getProspectOwnersFn = async (prospectId: string) => {
    return getZaplifySdk().profiles.prospects.getProspectOwners(prospectId);
};

type ProspectOwnersData = Awaited<ReturnType<typeof getProspectOwnersFn>>;

export const getProspectOwners = (
    prospectId: string,
    options?: Omit<UseQueryOptions<ProspectOwnersData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<ProspectOwnersData, Error> => {
    return {
        queryKey: getProspectOwnersCacheKey(prospectId),
        queryFn: () => getProspectOwnersFn(prospectId),
        enabled: !!prospectId,
        ...options,
    };
};
type GetOwnersParams = {
    prospectIds: string[];
    personIds: string[];
    linkedinUrls: string[];
    linkedinMemberIds: string[];
};

const getOwnersCacheKey = (params: GetOwnersParams) => [
    'getOwners',
    params.linkedinUrls.join(','),
    params.personIds.join(','),
    params.prospectIds.join(','),
    params.linkedinMemberIds.join(','),
];

const getOwnersFn = async (params: GetOwnersParams) => {
    return getZaplifySdk().profiles.prospects.getOwners(params);
};

type GetOwnersData = Awaited<ReturnType<typeof getOwnersFn>>;

export const getOwners = (
    params: GetOwnersParams,
    options?: Omit<UseQueryOptions<GetOwnersData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<GetOwnersData, Error> => {
    return {
        queryKey: getOwnersCacheKey(params),
        queryFn: () => getOwnersFn(params),
        enabled:
            !!params.prospectIds.length ||
            !!params.personIds.length ||
            !!params.linkedinUrls.length ||
            !!params.linkedinMemberIds.length,
        staleTime: 5 * 60 * 1000,
        ...options,
    };
};

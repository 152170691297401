import { BookOpen, ChevronDown, Target, MessageSquare, Users } from 'lucide-react';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { useQuery, useSubscription } from '@apollo/client';
import {
    GET_PLAYBOOKS,
    GetPlaybooksQuery,
    GetUserContactsSubscriptionSubscription,
    USER_CONTACTS_SUBSCRIPTION,
} from '@zaplify/graphql';
import { FC, useMemo, useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';
import { TypewriterText } from '../../../../components/typewriter-text';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { getInitials } from '../../../../functions/get-initials';
import { Button } from '@shadcn/ui/components/ui/button';
import { useSdk } from '../../../../sdk/use-sdk';
import { useMutation, useQuery as useQueryTan } from '@tanstack/react-query';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';

// Editable Text Component for inline editing
const EditableText: FC<{
    value: string;
    onChange: (value: string) => void;
    onSave: (value: string) => void;
    className?: string;
    multiline?: boolean;
}> = ({ value, onChange, onSave, className, multiline = false }) => {
    const [editValue, setEditValue] = useState(value);
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);
    const cursorPositionRef = useRef<{ start: number; end: number } | null>(null);

    // Use a separate ref to track if the component has been mounted
    // to prevent unnecessary cursor positioning on initial render
    const hasMounted = useRef(false);

    useEffect(() => {
        setEditValue(value);
    }, [value]);

    // Handle initial focus or when editing state changes
    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();

            // Only manually position cursor if component has already mounted
            // This prevents the cursor jumping issue on initial focus
            if (hasMounted.current) {
                // Set cursor at end of text
                const selection = window.getSelection();
                if (selection) {
                    const range = document.createRange();
                    range.selectNodeContents(inputRef.current);
                    range.collapse(false); // Collapse to end
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            } else {
                hasMounted.current = true;
            }
        }
    }, [editing]);

    // Restore cursor position after render if needed
    useEffect(() => {
        if (editing && inputRef.current && cursorPositionRef.current) {
            const selection = window.getSelection();
            if (selection) {
                try {
                    // Try to restore the cursor position
                    const range = document.createRange();
                    let { start, end } = cursorPositionRef.current;

                    // Safety check - ensure positions are within bounds
                    const nodeContent = inputRef.current.childNodes[0];
                    if (nodeContent) {
                        const maxLength = nodeContent.textContent?.length || 0;
                        start = Math.min(start, maxLength);
                        end = Math.min(end, maxLength);

                        range.setStart(nodeContent, start);
                        range.setEnd(nodeContent, end);
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                } catch (e) {
                    console.error('Error restoring cursor position:', e);
                }
            }
            // Reset the cursor position ref
            cursorPositionRef.current = null;
        }
    });

    // Update local state without saving
    const handleChange = (newValue: string) => {
        // Save current cursor position before state update
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0 && inputRef.current) {
            const range = selection.getRangeAt(0);
            if (inputRef.current.contains(range.startContainer)) {
                cursorPositionRef.current = {
                    start: range.startOffset,
                    end: range.endOffset,
                };
            }
        }

        setEditValue(newValue);
        onChange(newValue);
    };

    // Save only on blur (unfocus)
    const handleBlur = () => {
        setEditing(false);
        // Only save if value has changed
        if (editValue !== value) {
            onSave(editValue);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey && !multiline) {
            e.preventDefault();
            if (inputRef.current) {
                inputRef.current.blur(); // Trigger blur to save
            }
        }

        if (e.key === 'Escape') {
            e.preventDefault();
            setEditValue(value); // Reset to original value
            setEditing(false);
        }
    };

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div
                        className={cn(
                            'relative group focus-visible:outline-none text-xs text-gray-700 cursor-text',
                            editing ? '' : 'cursor-pointer',
                            className
                        )}
                        onClick={() => !editing && setEditing(true)}
                    >
                        <div
                            ref={inputRef}
                            contentEditable={editing}
                            onBlur={handleBlur}
                            onInput={(e) => handleChange((e.target as HTMLDivElement).innerText)}
                            onKeyDown={handleKeyDown}
                            suppressContentEditableWarning
                            className={cn(
                                'outline-none focus:outline-none p-1 rounded',
                                editing && 'ring-1 ring-blue-400',
                                multiline ? 'whitespace-pre-line' : 'whitespace-normal'
                            )}
                        >
                            {editValue}
                        </div>
                        {!editing && !editValue && (
                            <span className="text-gray-400 italic absolute inset-0 p-1">Click to add</span>
                        )}
                    </div>
                </TooltipTrigger>
                <TooltipContent>{editing ? 'Automatically saves' : <p>Click to edit</p>}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default function FirstPlaybookCard({ displayContacts }: { displayContacts: boolean }) {
    const { data: playbooks } = useQuery(GET_PLAYBOOKS, {
        variables: {
            name: '%',
        },
    });
    const firstPlaybook = useMemo(() => playbooks?.Groups?.[0], [playbooks]);
    const { data: contacts } = useSubscription(USER_CONTACTS_SUBSCRIPTION);

    const addedContacts = useMemo(
        () =>
            contacts?.UserContacts.filter((contact) =>
                contact._GroupToUserContacts.find((group) => group.Group.id === firstPlaybook?.id)
            ),
        [contacts, firstPlaybook]
    );

    if (!firstPlaybook) {
        return null;
    }

    return (
        <Card className="overflow-hidden border-l-4 border-l-blue-500">
            <CardContent className="p-0">
                <div className="bg-blue-50 px-4 py-2 flex items-center gap-2">
                    <BookOpen size={16} className="text-brand-600" />
                    <span className="font-medium text-sm">Playbooks guide</span>
                </div>
                <div className="p-4">
                    <p className="text-gray-600 text-sm pb-3">
                        <TypewriterText
                            text={`Based on your profile, we've created your first playbook to help you ${firstPlaybook.AssistantSetting.purpose.toLowerCase()}. We're analyzing recent conversations and adding contacts we think match your first playbook to help you get started.`}
                            delay={0.5}
                        />
                    </p>
                    <PlaybookPreview playbook={firstPlaybook} />
                </div>
                <AnimatePresence>
                    <motion.div
                        className="p-4"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="flex flex-col gap-2 justify-start items-start mb-4">
                            <div>
                                <h3 className="text-lg font-medium">
                                    {displayContacts
                                        ? 'We added these initial contacts to your Playbook'
                                        : 'We are finding your first contacts'}
                                </h3>
                                <p className="text-sm text-gray-600">
                                    {displayContacts
                                        ? "Remove any contacts you don't want to keep talking to"
                                        : 'Waiting for Conversation Analysis to finish...'}
                                </p>
                            </div>
                            <div className="flex flex-col gap-2 w-full max-h-[300px] overflow-y-auto px-1">
                                {displayContacts ? (
                                    <>
                                        {addedContacts?.map((contact) => (
                                            <AddedContactRow key={contact.prospectId} contact={contact} />
                                        ))}
                                    </>
                                ) : (
                                    <div className="flex flex-col items-start gap-2 h-full">
                                        <Skeleton className="w-5/6 h-4" />
                                        <Skeleton className="w-4/5 h-4" />
                                        <Skeleton className="w-5/6 h-4" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </motion.div>
                </AnimatePresence>
                <div className="bg-blue-50/50 p-4 border-t border-blue-100">
                    <div className="flex items-start gap-2">
                        <div className="mt-1">
                            <BookOpen size={16} className="text-brand-600" />
                        </div>
                        <div>
                            <h4 className="font-medium text-sm mb-1">
                                Playbooks affect your messages and recommendations
                            </h4>
                            <p className="text-xs text-gray-600">
                                The Playbooks you create will help you organize your network and customize your
                                communication approach for different contacts. Each playbook can have their own goal,
                                target group, and custom instructions.
                            </p>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

const PlaybookPreview: FC<{ playbook: GetPlaybooksQuery['Groups'][0] }> = ({ playbook }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const {
        playbook: { updateAssistantSettings },
    } = useSdk();
    const { mutateAsync: updatePlaybook } = useMutation(updateAssistantSettings());

    const handleSaveField = async (field: string, value: string) => {
        try {
            await updatePlaybook({
                assistantSettingsId: playbook.AssistantSetting.id,
                updatedAssistantSettings: {
                    [field]: value,
                },
            });
            toast({ title: `${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully` });
        } catch (error) {
            toast({ title: `Failed to update ${field}`, variant: 'destructive' });
            console.error(`Error updating ${field}:`, error);
        }
    };

    return (
        <div className="flex flex-col bg-background-secondary p-4 rounded-xl border border-brand-300/30 shadow-sm relative overflow-hidden gap-3">
            <div className="flex justify-start gap-3 items-center">
                <img src={playbook.imgSrc} alt={playbook.name} className="w-10 h-10 rounded-md" />

                <h3 className="text-lg font-medium">{playbook.name}</h3>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white/60 rounded-lg px-3 py-2 border border-gray-100 relative group">
                    <div className="flex items-center gap-2 mb-2">
                        <Target size={16} className="text-brand-600" />
                        <h4 className="text-sm font-medium">Purpose</h4>
                    </div>
                    <EditableText
                        value={playbook.AssistantSetting.purpose}
                        onChange={() => {}}
                        onSave={(value) => handleSaveField('purpose', value)}
                    />
                </div>

                <div className="bg-white/60 rounded-lg px-3 py-2 border border-gray-100 relative group">
                    <div className="flex items-center gap-2 mb-2">
                        <Users size={16} className="text-brand-600" />
                        <h4 className="text-sm font-medium">Target Group</h4>
                    </div>
                    <EditableText
                        value={playbook.AssistantSetting.targetGroup || ''}
                        onChange={() => {}}
                        onSave={(value) => handleSaveField('targetGroup', value)}
                    />
                </div>
            </div>

            <div className="relative">
                <AnimatePresence initial={false}>
                    <div className="flex items-center gap-2 mb-2">
                        <MessageSquare size={16} className="text-brand-600" />
                        <h4 className="text-sm font-medium">Initial Playbook Instructions</h4>
                    </div>
                    <motion.div
                        className="bg-white/60 rounded-lg p-3 border border-gray-100 relative overflow-hidden group"
                        initial={{ height: '130px' }}
                        animate={{ height: isExpanded ? 'auto' : '130px' }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    >
                        <EditableText
                            value={playbook.AssistantSetting.context || ''}
                            onChange={() => {}}
                            onSave={(value) => handleSaveField('context', value)}
                            multiline={true}
                            className="text-gray-600 text-xs"
                        />

                        {!isExpanded && (
                            <motion.div
                                className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white/60 to-transparent"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            />
                        )}
                    </motion.div>

                    <motion.div
                        className="cursor-pointer flex items-end justify-center py-2"
                        onClick={() => setIsExpanded(!isExpanded)}
                        initial={{ opacity: 0.8 }}
                        whileHover={{ opacity: 1, scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <div className="flex items-center gap-1 text-sm text-gray-600 hover:text-gray-800 transition-colors">
                            <span>{isExpanded ? 'Hide instructions' : 'Show instructions'}</span>
                            <ChevronDown
                                size={16}
                                className={cn(
                                    'transition-transform duration-300',
                                    isExpanded && 'transform rotate-180'
                                )}
                            />
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
};

const AddedContactRow: FC<{ contact: GetUserContactsSubscriptionSubscription['UserContacts'][0] }> = ({ contact }) => {
    const {
        userContacts: { deleteUserContacts: deleteUserContactMutation },
        prospect: { getProspectById: getProspectByIdQuery },
    } = useSdk();
    const { mutateAsync: deleteUserContact, isPending } = useMutation(deleteUserContactMutation());
    const { data: prospect } = useQueryTan(getProspectByIdQuery(contact.prospectId));

    const removeContact = async () => {
        try {
            await deleteUserContact({
                contactIds: [contact.id],
            });
            toast({
                title: 'Contact Removed',
                description: `${prospect?.data?.fullName} has been removed from your Playbook.`,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to remove contact. Please try again.',
                variant: 'destructive',
            });
        }
    };

    return (
        <div
            className={cn(
                'flex items-center justify-between gap-2 p-2 py-3 rounded-lg hover:bg-gray-50 border border-gray-200',
                isPending && 'opacity-50 cursor-not-allowed pointer-events-none animate-pulse'
            )}
        >
            <div className="flex items-center gap-3">
                <Avatar className="h-10 w-10">
                    <AvatarImage src={prospect?.data?.linkedinProfileImgUrl} alt={prospect?.data?.fullName} />
                    <AvatarFallback>{getInitials(prospect?.data?.fullName || '')}</AvatarFallback>
                </Avatar>
                <div>
                    <div className="flex items-center gap-2">
                        <h4 className="font-medium text-sm">{prospect?.data?.fullName}</h4>
                    </div>
                    <p className="text-sm text-gray-600">
                        {prospect?.data?.occupationTitle} at {prospect?.data?.organizationName}
                    </p>
                </div>
            </div>
            <Button variant="outline" size="sm" onClick={removeContact} disabled={isPending}>
                Remove
            </Button>
        </div>
    );
};
